import {
  Audit,
  ContractLanguage,
  ContractLanguagePrefix,
  getClassificatorValue,
  Network,
  NetworkPrefix,
  ReportType,
  ReportTypePrefix,
  ReportTemplate,
  ReportTemplatePrefix,
  Profile,
} from 'interfaces';

import { getUid, subtractOffsetDate } from 'helpers';

const getDefaultAudit = (profile: Profile | null): Audit => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  subtractOffsetDate(now);

  return {
    projectName: '',
    projectUrl: '',
    networks: [
      {
        network: getClassificatorValue(Network, NetworkPrefix.BSC),
      },
    ],
    projectLanguage: getClassificatorValue(
      ContractLanguage,
      ContractLanguagePrefix.SOLIDITY,
    ),
    contracts: [],
    dateStart: now,
    dateEnd: now,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    ...getDefaults(profile?.reportType),
  } as Audit;
};

export default getDefaultAudit;

interface ReportDefaults {
  type: ReportType;
  description: null | {};
  disclaimer: null | {};
  chapters: any[];
  appendixes: any[];
}

const getDefaults = (reportTemplate?: ReportTemplate): ReportDefaults | null => {
  if (!reportTemplate) return null;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return defaults[reportTemplate];
};

const defisecDefaults: ReportDefaults = {
  type: getClassificatorValue(ReportType, ReportTypePrefix.SIMPLE),
  disclaimer: null,
  description:
  {
    time: Date.now(),
    blocks: [
      {
        id: getUid(),
        type: 'paragraph',
        data: {
          text: '{{introduction}}',
        },
      }
    ],
    version: '2.22.1',
  },
  chapters: [],
  appendixes: [
    {
      title: 'Conclusion',
      body: {
        time: Date.now(),
        blocks: [
          {
            type: 'paragraph',
            data: {
              text: '{{issues-summary}}',
            },
          },
        ],
      },
    },
    {
      title: 'Disclaimer',
      body: {
        time: Date.now(),
        blocks: [
          {
            type: 'paragraph',
            data: {
              text:
                'This report is subject to the terms and conditions (including without limitation, description of '
                + 'services, confidentiality, disclaimer and limitation of liability) set forth in the Services '
                + 'Agreement, or the scope of services, and terms and conditions provided to the Company in '
                + 'connection with the Agreement. This report provided in connection with the Services set '
                + 'forth in the Agreement shall be used by the Company only to the extent permitted under the '
                + 'terms and conditions set forth in the Agreement. This report may not be transmitted, '
                + 'disclosed, referred to or relied upon by any person for any purposes without DeFiSec prior '
                + 'written consent.',
            },
          },
          {
            type: 'paragraph',
            data: {
              text:
                'This report is not, nor should be considered, an “endorsement” or “disapproval” of any '
                + 'particular project or team. This report is not, nor should be considered, an indication of the '
                + 'economics or value of any “product” or “asset” created by any team or project that contracts '
                + 'DeFiSec to perform a security assessment. This report does not provide any warranty or '
                + 'guarantee regarding the absolute bug-free nature of the technology analyzed, nor do they '
                + 'provide any indication of the technologies proprietors, business, business model or legal '
                + 'compliance.',
            },
          },
          {
            type: 'paragraph',
            data: {
              text:
                'This report should not be used in any way to make decisions around investment or '
                + 'involvement with any particular project. This report in no way provides investment advice, '
                + 'nor should be leveraged as investment advice of any sort. This report represents an '
                + 'extensive assessing process intending to help our customers increase the quality of their '
                + 'code while reducing the high level of risk presented by cryptographic tokens and blockchain '
                + 'technology.',
            },
          },
          {
            type: 'paragraph',
            data: {
              text:
              'Blockchain technology and cryptographic assets present a high level of ongoing risk. DSA’s position is '
              + 'that each company and individual are responsible for their own due diligence and continuous security. '
              + 'DSA’s goal is to help reduce the attack vectors and the high level of variance associated with utilizing '
              + 'new and consistently changing technologies, and in no way claims any guarantee of security or '
              + 'functionality of the technology we agree to analyze.',
            },
          },
        ],
      },
    },
  ]
};

// @ts-ignore
const defaults: { [x in ReportTemplate]: ReportDefaults } = {
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.DEFISEC)]:
    defisecDefaults,
};
