import ProtectedService from './ProtectedService';

class SeverityService extends ProtectedService {
    baseUrl = '/issues-description';

    getSeverityList = async () => this.get();

    createSeverity = async (name: string, color: string, description: string) => this.post({
      name, color, description
    });

    editSeverity = async (id:number, name: string, color: string, description: string) => this.patch({
      name, color, description
    }, `${id}`);

    deleteSeverity = async (id: number) => this.delete(null, `${id}`);

    activateSeverity = async (id: number) => this.post(null, `${id}/restore`);

    changeOrder = async (order: number[]) => this.post(order, 'order');
}

export const severityService = new SeverityService();

export default SeverityService;
