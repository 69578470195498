import React, { useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import {
  Col, Row, Collapse, Card
} from 'react-bootstrap';
import { FaPlay, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ErrorCode, useDropzone } from 'react-dropzone';
// @ts-ignore
import { diffLines, formatLines } from 'unidiff';
import {
  parseDiff, Diff, tokenize, markEdits
  // @ts-ignore
} from 'react-diff-view';
import { isAddress } from 'web3-utils';
import Input from '../Input';
import Button from '../Button';
import { getErrorMessage } from '../../helpers';
import { forkCheckerService } from '../../services/forkCheckerService';

import 'react-diff-view/style/index.css';
import './index.css';

const tokenizeFn = (hunks: any) => {
  if (!hunks) {
    return undefined;
  }

  const options = {
    highlight: false,
    enhancers: [markEdits(hunks, { type: 'block' })],
  };

  try {
    return tokenize(hunks, options);
  } catch (ex) {
    return undefined;
  }
};

const ForkCheckerPage: React.FC<{}> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [invalidAddress, setInvalidAddress] = useState<boolean>(false);
  const [address, setAddress] = useState<string>();
  const [network, setNetwork] = useState<string>();
  const [contracts, setContracts] = useState<Array<any>>([]);
  const [collapsedContracts, setCollapsedContracts] = useState<any>({});
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
  const networkOptions = useMemo(() => ([
    {
      value: 'ETHEREUM',
      label: 'Ethereum mainnet',
    },
    {
      value: 'BINANCE_SMART_CHAIN',
      label: 'Binance smart chain',
    }
  ]), []);
  const toggleCollapse = (contract: { entitySHA256: string; }) => {
    setCollapsedContracts({
      ...collapsedContracts,
      [contract.entitySHA256]: !collapsedContracts[contract.entitySHA256]
    });
  };
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    maxFiles: 1,
    validator: (file) => {
      // @ts-ignore
      if (!/.sol$/.test(file.path)) {
        return {
          code: ErrorCode.FileInvalidType,
          message: 'Wrong file extension'
        };
      }
      return null;
    },
    onDropAccepted: (files) => {
      setSelectedFiles([...files]);
    }
  });

  const onFileRemove = (e: any) => {
    e.stopPropagation();
    setSelectedFiles([]);
  };

  const notEnoughData = (!address || !network) && !selectedFiles.length;

  const checkContract = async () => {
    if (notEnoughData || loading) return;
    setContracts([]);
    setCollapsedContracts({});
    const requestByAddress = address && network;
    let validAddress = true;
    if (requestByAddress) {
      setSelectedFiles([]);
      validAddress = isAddress(address!);
    }
    setInvalidAddress(!validAddress);
    if (!validAddress) {
      return;
    }

    setLoading(true);
    setDirty(true);

    try {
      let response;
      if (requestByAddress) {
        response = await forkCheckerService.checkByAddress(address!, network!);
      } else {
        const blobToData = (blob: Blob): Promise<string> => new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.readAsText(blob);
        });
        const file = selectedFiles[0];
        const text = await blobToData(file);
        response = await forkCheckerService.checkByText(text);
      }

      const newContracts = response.map((item: any) => {
        const exact = item.findMode === 1;
        const bestMatch = !exact ? item.matches.sort((a: any, b: any) => b.tunedSimilarity - a.tunedSimilarity)[0] : null;
        const result = {
          entitySHA256: item.entitySHA256,
          entityName: item.entityName,
          entityCode: item.entityCode,
          exact,
          bestMatch,
          order: (exact && 1) || (bestMatch ? bestMatch.tunedSimilarity : 0),
          diff: undefined
        };

        if (bestMatch) {
          const lines = diffLines(item.entityCode.replaceAll('\r', ''), bestMatch.contents.replaceAll('\r', ''));
          const diffText = formatLines(lines, { context: 3 });
          const [diff] = parseDiff(diffText);
          result.diff = {
            ...diff,
            tokens: tokenizeFn(diff.hunks)
          };
        }
        return result;
      }).sort((a: any, b: any) => a.order - b.order);
      setContracts(newContracts);

      setCollapsedContracts(newContracts.map((contract: { entitySHA256: string; }) => contract.entitySHA256).reduce((acc: any, sha: string) => {
        acc[sha] = true;
        return acc;
      }, {}));

      toast.success('Success');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row className="mb-3">
        <Col xs={12} md={5} className="">
          <div className="fc-item-bottom">
            <Input
              onChange={(e) => setAddress(e.target.value)}
              defaultValue={address}
            />
          </div>
        </Col>
        <Col xs={6} md={4} className="">
          <div className="fc-item-bottom">
            <ReactSelect
              placeholder="Select network"
              options={networkOptions}
              onChange={(e) => setNetwork(e?.value)}
              menuPlacement="auto"
              menuPosition="fixed"
            />
          </div>
        </Col>
        <Col xs={6} md={3} className="">
          <div className="fc-item-bottom fc-to-right">
            <Button disabled={notEnoughData} loading={loading} onClick={checkContract} className="fc-button">
              <FaPlay />
              {' '}
              Check
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <div className="container">
          <div {...getRootProps()} className={`drop-base-styled${isFocused ? ' drop-base-styled__focused' : ''}${isDragAccept ? ' drop-base-styled__accepted' : ''}${isDragReject ? ' drop-base-styled__rejected' : ''}`}>
            <input {...getInputProps()} />
            <p>Drag n drop file here, or click to select file</p>
            <em>(Only one *.sol file will be accepted)</em>
          </div>
          <div className="fc-file-list">
            <ul>
              {
                selectedFiles.map((file) => (
                  <li key={file.name}>
                    {file.name}
                    {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
                    <div role="button" title="Remove file" className="fc-remove-file" onClick={onFileRemove} onKeyUp={onFileRemove}>
                      <FaTimes color="red" />
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </Row>
      {contracts.map((contract) => (
        <Card key={contract.entitySHA256} className="fc-card">
          <Card.Header onClick={() => toggleCollapse(contract)}>
            <div className="fc-card-header">
              <b>{contract.entityName}</b>
              {'      '}
              {contract.exact ? <em>exact match</em> : ''}
              {!contract.exact && !contract.bestMatch ? <em>No matches found</em> : ''}
              {contract.bestMatch
                ? (
                  <em>
                    similarity:
                    {'  '}
                    {contract.bestMatch.tunedSimilarity.toFixed(2)}
                  </em>
                )
                : ''}
            </div>
          </Card.Header>
          <Collapse in={!collapsedContracts[contract.entitySHA256]}>
            <Card.Body>
              <Row>
                <Col xs={12} md={12}>
                  {
                    contract.bestMatch
                      ? (
                        <div className="fc-match-meta">
                          <p className="fc-meta-item">
                            <b>Name: </b>
                            {contract.bestMatch.name}
                          </p>
                          <p className="fc-meta-item">
                            <b>Repository: </b>
                            {contract.bestMatch.repository}
                          </p>
                          <p className="fc-meta-item">
                            <b>Branch: </b>
                            {contract.bestMatch.branch}
                          </p>
                          <p className="fc-meta-item">
                            <b>Commit: </b>
                            {contract.bestMatch.commit}
                          </p>
                          <p className="fc-meta-item">
                            <b>Solidity version: </b>
                            {contract.bestMatch.solidityVersion}
                          </p>
                        </div>
                      )
                      : ''
                  }
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <div>
                    {
                      contract.diff
                        ? (
                          <div className="diff-wrapper">
                            {contract.diff.type
                              ? (
                                <Diff
                                  viewType="split"
                                  diffType={contract.diff.type}
                                  hunks={contract.diff.hunks || []}
                                  tokens={contract.diff.tokens}
                                  optimizeSelection
                                />
                              )
                              : null}
                          </div>
                        )
                        : ''
                    }
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Collapse>
        </Card>
      ))}
      { dirty && !invalidAddress && !loading && !contracts.length ? <div>No contract found!</div> : null }
      { invalidAddress ? <div>Invalid address!</div> : null }
    </>
  );
};

export default ForkCheckerPage;
