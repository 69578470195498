import ProtectedService from './ProtectedService';

export enum EnumTypes {
  'AUDIT_STATUS' = 0,
  'ISSUE_STATUS' = 1,
  'ISSUE_SEVERITY' = 2,
  'NETWORK' = 3,
  'CODE_LANGUAGE' = 4,
  'AUDIT_TYPE' = 5,
  'CONTRACT_TYPE' = 6,
  'CONTRACT_TAGS' = 7,
}

class EnumsService extends ProtectedService {
    baseUrl = '/enums';

    switchActive = async (type: number, key: number, active: string) => this.post({
      active,
    }, `${type}/${key}/switch-active`)

    updateOne = async (type: number, key: number, value: string) => this.patch({
      value,
    }, `${type}/${key}`)

  createOne = async (type: number, value: string) => this.post({
    value,
  }, `${type}`)
}

export const enumsService = new EnumsService();

export default EnumsService;
