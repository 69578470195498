import React, { useState } from 'react';
import { Row, Col, CloseButton } from 'react-bootstrap';
import { FaRocket } from 'react-icons/fa';
import Button from 'components/Button/index';
import { toast } from 'react-toastify';
import Popup from '../../Popup/Popup';
import PublishDataForm from '../../../forms/PublishData';
import { auditService } from '../../../services/AuditService';
import { getErrorMessage } from '../../../helpers';

interface PublishReportButtonProps {
  id: number;
  short?: boolean;
  url?: string;
  name: string;
  auditStatus: number;
  size?: 'sm' | 'lg';
  onChange?: () => void;
}

const PublishReportButton: React.FC<PublishReportButtonProps> = ({
  id,
  short,
  url,
  size,
  name,
  onChange,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(false);

  const confirm = () => {
    setShowConfirmationPopup(true);
  };

  const closeConfirm = () => {
    setShowConfirmationPopup(false);
  };

  const publish = () => {
    setShowPopup(true);
    closeConfirm();
  };

  const onSubmit = async (data: any) => {
    if (loading) return;
    setLoading(true);
    try {
      await auditService.publishAudit(
        id,
        data.path,
        data.name
      );

      toast.success('Report published!');
      setShowPopup(false);
      if (onChange) {
        onChange();
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    if (loading) return;
    setShowPopup(false);
  };

  return (
    <>
      <Button
        size={size}
        /* eslint-disable-next-line no-nested-ternary */
        title={short ? (url ? 'Republish' : 'Publish') : undefined}
        variant="success"
        onClick={url ? confirm : publish}
        loading={loading}
      >
        <FaRocket />
        {' '}
        {/* eslint-disable-next-line no-nested-ternary */}
        {short ? null : (url ? 'Republish' : 'Publish')}
      </Button>
      <Popup showPopup={showPopup} half>
        <PublishDataForm
          onSubmit={onSubmit}
          onClose={onClose}
          state={{
            path: name,
            name,
          }}
          isSubmitting={loading}
          btnName={url ? 'Republish' : 'Publish'}
        />
      </Popup>

      <Popup showPopup={showConfirmationPopup} half>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={closeConfirm} />
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <p className="h5">Are you sure you want to republish this report?</p>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col className="d-flex justify-content-end">
            <Button className="btn-success m-0" onClick={publish}>
              Republish
            </Button>
          </Col>
        </Row>
      </Popup>
    </>
  );
};

export default PublishReportButton;
