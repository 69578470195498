import ProtectedService from './ProtectedService';

class AuthService extends ProtectedService {
  baseUrl = '/auth';

  login = async (data: { login: string; pwd: string }) => {
    const url = this.getUrl('login');
    const response = await this.api.post(url, data, {
      headers: { Authorization: '' },
    });
    return response.data;
  };

  logout = async () => {
    const response = await this.get(null, 'logout');
    this.clearAccessToken();
    return response;
  };

  changePassword = async (data: {
    login: string;
    pwd: string;
    newPwd: string;
    confirmedNewPwd: string;
  }) => {
    const url = this.getUrl('change_pwd');
    const { ...result } = data;
    const response = await this.api.post(url, result, {
      headers: { Authorization: '' },
    });
    this.setAccessToken(response.data.access_token);
    return response;
  };

  profile = async () => {
    const response = await this.get(null, 'profile');
    return response;
  };
}

export const authService = new AuthService();

export default AuthService;
