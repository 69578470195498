import axios from 'axios';

import { API_BASE_URL } from 'config';

const api = axios.create({
  baseURL: API_BASE_URL,
});

class BaseService {
  baseUrl: string = '/';

  api = api;

  getUrl = (subUrl?: string) => {
    let url = this.baseUrl;
    if (subUrl) url = `${url}/${subUrl}`;
    return url;
  };

  async get(params: any = {}, subUrl?: string, headers?: any) {
    const url = this.getUrl(subUrl);
    const response = await this.api.get(url, { params, headers });
    return response.data;
  }

  async post(params: any = {}, subUrl?: string, headers?: any) {
    const url = this.getUrl(subUrl);
    const response = await this.api.post(url, params, { headers });
    return response.data;
  }

  async patch(params: any = {}, subUrl?: string, headers?: any) {
    const url = this.getUrl(subUrl);
    const response = await this.api.patch(url, params, { headers });
    return response.data;
  }

  async delete(params: any = {}, subUrl?: string, headers?: any) {
    const url = this.getUrl(subUrl);
    const response = await this.api.delete(url, { params, headers });
    return response.data;
  }
}

export default BaseService;
