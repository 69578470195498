import React, { useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { AuditInTable, User } from '../../interfaces';
import { auditService } from '../../services/AuditService';
import { getErrorMessage } from '../../helpers';
import { useUser } from '../../providers/UserProvider';

const AuditorsSelect: React.FC<{
  audit: AuditInTable;
  auditors: Array<User>;
  fetchAudits: any;
}> = ({ audit, auditors, fetchAudits }) => {
  const [values] = useState<Array<number>>(
    audit.editors?.split(',').map(Number) || [],
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const user = useUser();
  const options = useMemo(
    () => auditors.map((auditor: User) => ({
      value: auditor.id,
      label: auditor.name,
      isRemovable:
          user?.isAdmin
          || (auditor.id !== audit.currentUser
            && auditor.id !== user?.id
            && !auditor.roles!.split(';').includes('admin')),
    })),
    [auditors, audit.currentUser, user?.id],
  );

  const customStyles = {
    multiValueRemove: (base: any, props: any) => ({
      ...base,
      display: props.data.isRemovable ? 'flex' : 'none',
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 1000,
    }),
  };

  const value = options.filter((c) => values.includes(c.value));
  const onChange = async (val: any) => {
    if (isSaving) return;
    setIsSaving(true);
    try {
      const v = val.map((item: any) => item.value);
      await auditService.saveAuditors(audit.id as number, v);
      toast.success('Auditors are saved');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsSaving(false);
    }
    await fetchAudits();
  };

  return (
    <ReactSelect
      options={options}
      value={value}
      onChange={onChange}
      isMulti
      isClearable={false}
      menuPlacement="auto"
      menuPosition="fixed"
      styles={customStyles}
    />
  );
};

export default AuditorsSelect;
