import React, { useEffect, useState } from 'react';
import { FaShare } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Button from 'components/Button/index';
import { StandardIssue } from '../../../interfaces';
import { getErrorMessage } from '../../../helpers';
import Popup from '../../Popup/Popup';
import { standardIssuesService } from '../../../services/StandardIssuesService';
import StandardIssueForm from '../../../forms/StandardIssue';

const parseIssue = (issue: any) => ({
  ...issue,
  description: issue.description ? JSON.parse(issue.description) : null,
});

const SelectStandardIssueButton: React.FC<{
  id: number;
  onSelect: (data: any) => void;
}> = ({ id, onSelect }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [issue, setIssue] = useState<StandardIssue | null>(null);
  const fetchIssue = async () => {
    try {
      const response = await standardIssuesService.get(null, `${id}`);
      setIssue(parseIssue(response));
      setShowPopup(true);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    onSelect(data);
    setShowPopup(false);
  };

  const onClose = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (loading) {
      fetchIssue();
    }
  }, [loading]);

  return (
    <>
      <Button
        size="sm"
        title="View"
        variant="dark"
        onClick={() => setLoading(true)}
        loading={loading}
      >
        <FaShare />
      </Button>
      <Popup showPopup={showPopup} half nested>
        <StandardIssueForm
          selectMode
          state={issue!}
          onSubmit={onSubmit}
          onCreateClose={onClose}
        />
      </Popup>
    </>
  );
};

export default SelectStandardIssueButton;
