import React, { useMemo } from 'react';

import { useUser } from 'providers/UserProvider';
import { useEnums } from 'providers/EnumsProvider';
import { EnumTypes } from '../../services/EnumsService';
import Select, { SelectProps } from './Select';

// eslint-disable-next-line react/display-name
const ResolutionSelect: React.FC<SelectProps> = React.forwardRef(
  (props, ref) => {
    const profile = useUser();
    const { filterEnums, enums } = useEnums();

    const options = useMemo(() => {
      const items = filterEnums(EnumTypes.ISSUE_STATUS);
      return items.map((item: any) => ({
        value: item.key,
        label: item.value,
      }));
    }, [enums, profile]);

    return <Select {...props} ref={ref} options={options} />;
  },
);

export default ResolutionSelect;
