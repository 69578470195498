export interface SelectOption {
  value: any;
  label: string;
  isDisabled?: boolean;
}

export const getClassificatorValue = (ENUM: any, prefix: string): number => ENUM[`${prefix}_${process.env.REACT_APP_ENV}`];

export enum ContractLanguagePrefix {
  SOLIDITY = 'SOLIDITY',
  RUST = 'RUST',
  C = 'C',
}

export enum ReportTypePrefix {
  SIMPLE = 'SIMPLE',
  NORMAL = 'NORMAL',
  PRO = 'PRO',
  COMPLEX = 'COMPLEX'
}

export enum ReportTemplatePrefix {
  DEFISEC = '1',
}

export enum ReportTemplate {
  '1_STAGE' = 1,
  '1_PROD' = 1,
}

export enum NetworkPrefix {
  BSC = 'BSC',
  SOLANA = 'SOLANA',
}

export enum AuditStatusPrefix {
  IN_WORK = 'IN_WORK',
  FINAL = 'FINAL_REPORT',
}

export enum ContractLanguage {
  'SOLIDITY_STAGE' = 0,
  'SOLIDITY_PROD' = 0,
  'RUST_STAGE' = 1,
  'RUST_PROD' = 1,
  'C_STAGE' = 2,
  'C_PROD' = 2,
}

export enum ReportType {
  'SIMPLE_STAGE' = 0,
  'SIMPLE_PROD' = 0,
  'NORMAL_STAGE' = 1,
  'NORMAL_PROD' = 1,
  'PRO_STAGE' = 2,
  'PRO_PROD' = 2,
  'COMPLEX_STAGE' = 3,
  'COMPLEX_PROD' = 3,
}

export enum Network {
  'BSC_STAGE' = 0,
  'BSC_PROD' = 0,
  'SOLANA_STAGE' = 8,
  'SOLANA_PROD' = 12,
}

export enum AuditStatus {
  'IN_WORK_STAGE' = 0,
  'IN_WORK_PROD' = 0,
  'FINAL_REPORT_STAGE' = 3,
  'FINAL_REPORT_PROD' = 3,
  'CANCELLED_STAGE' = 5,
  'CANCELLED_PROD' = 4,
}

export interface Issue extends Sortable {
  id?: number;
  severity: Severity;
  name: string;
  description: any;
  recommendation: any;
  resolution: number;
  comment: Comment[];
  standard?: boolean;
}

export interface Chapter extends Sortable {
  id?: number;
  title: string;
  body: any;
}

export interface AuditChapter extends Sortable {
  id?: number;
  title: string;
  body: any;
  appendix?: number;
}

export interface Comment {
  title: string;
  body: any;
}

export interface Contract extends Sortable {
  id?: number;
  name: string;
  address: string;
  type?: number;
  description: string;
  issues: Issue[];
  chapters: Chapter[];
}

export interface Sortable {
  order: number;
}

export interface Audit {
  id?: number;
  projectName: string;
  projectLanguage: number;
  projectUrl: string;
  description: any;
  disclaimer: any;
  contracts: Contract[];
  currentUser?: User;
  editors?: Editor[];
  status?: number;
  type: number;
  dateStart: Date;
  dateEnd: Date;
  chapters: AuditChapter[];
  appendixes: AuditChapter[];
  networks: ProjectNetwork[];
  userLastUpdate?: Date;
  company?: number;
  externalUrl?: string;
}

export interface AuditInTable {
  id: number;
  projectName: string;
  projectLanguage: number;
  projectUrl: string;
  contractsLength: number;
  issuesLength: number;
  currentUser: number;
  editors: string;
  status: number;
  dateStart: Date;
  networks: string;
  userLastUpdate: Date;
}

export interface Editor {
  id?: number;
  editor: User;
}

export interface ProjectNetwork {
  id?: number;
  network: number;
}

export interface User {
  id: number;
  name: string;
  blocked: boolean;
  roles?: string;
}

export interface Profile {
  id: number;
  name: string;
  roles: string;
  company: string;
  reportType: number;
  isAdmin: boolean;
}

export interface IssueInTable {
  id: number;
  name: string;
  projectName: string;
  projectId: number;
  contractName: string;
  contractType: number;
  resolution: number;
  severity: number;
  status: number;
  standard: boolean;
  editors: string;
  currentUser: number | null
}

export interface AutoAuditsInTable {
  id: number;
  dateTimeStamp: string;
  tokenName: string;
  tokenSymbol: string;
  tokenAddress: string;
  explorerLink: string;
  networkName: string;
  totalSupply: string;
  externalUrl: string;
  published: boolean;
  price: string;
  tvl: string;
  tvlUsd: string;
  type: string;
}

export interface StandardIssueInTable {
  id: number;
  createdAt: Date;
  name: string;
  contractType: number;
  severity: number;
  tags: string;
  isDeleted: boolean;
}

export interface StandardIssue {
  id: number;
  createdAt: Date;
  name: string;
  description: string;
  contractType: number;
  severity: number;
  tags: Array<StandardIssueContractTag>;
}

export interface StandardIssueContractTag {
  id?: number;
  contractTag: number;
}

export interface TemplatesFormInterface {
  introduction?: any;
  publishInWork?: any;
  publishFinal?: any;
}

export interface Template {
  type: number;
  template: string;
}

export interface Severity {
  id: number;
  name: string;
  color: string;
  importance: number;
  description: string;
  isDeleted: boolean;
}
