import React, { useState, useEffect } from 'react';
import { Row, Col, CloseButton } from 'react-bootstrap';
import classnames from 'classnames';
import { FaArrowDown, FaArrowUp, FaTimes } from 'react-icons/all';
import { useFieldArray } from 'react-hook-form';
import Button from 'components/Button';
import { useFormContext } from 'components/Form/context';
import './List.css';
import Popup from '../Popup/Popup';

export interface ListProps<T> {
  name: string;
  value: T[];
  editable?: boolean;
  sortable?: boolean;
  onChange: (value: T[]) => void;
  className?: string;
  initialValue?: T;
  addText?: string;
  component: any;
  ref: any;
}

const List = <T extends {}>(props: ListProps<T>) => {
  const {
    className,
    initialValue = {} as T,
    component: Component,
    editable = true,
    sortable = false,
    addText = 'Add',
    name
  } = props;

  const { control } = useFormContext();
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined,
  );

  const {
    fields, append, remove, move,
  } = useFieldArray({
    control,
    name: name as `${string}`
  });

  const classNames = classnames('list', className);
  // const handleChange = (index: number) => (item: T) => {
  //     update(index, item)
  // }

  const onAdd = () => {
    append(initialValue);
  };

  const onDeleteClick = (index: number) => () => {
    setShowDelete(true);
    setSelectedIndex(index);
  };

  const moveItem = (index: number, up = false) => () => {
    move(index, index + (up ? -1 : 1));
  };

  const onRemoveConfirm = () => {
    remove(selectedIndex);
    setShowDelete(false);
    setSelectedIndex(undefined);
  };

  const onRemoveReject = () => {
    setShowDelete(false);
    setSelectedIndex(undefined);
  };

  const onKeydown = (e: KeyboardEvent) => {
    if (e.code === 'KeyC' && (e.ctrlKey || e.metaKey) && e.shiftKey) {
      e.preventDefault();
      onAdd();
    }
  };

  useEffect(() => {
    if (name === 'contracts') {
      document.addEventListener('keydown', onKeydown);
    }

    return () => {
      if (name === 'contracts') {
        document.removeEventListener('keydown', onKeydown);
      }
    };
  }, []);

  return (
    <div className={classNames}>
      <div className="list__items">
        {fields.map((item, index) => (
          <div key={item.id} className="list__item">
            <div className="list__item-content">
              <Component name={`${name}.${index}`} index={index} value={item} />
            </div>
            {editable && (
              <div className="list__item-tools">
                {sortable && (
                  <>
                    {index < fields.length - 1 && (
                      <Button
                        title="Move down"
                        size="sm"
                        variant="primary"
                        onClick={moveItem(index)}
                      >
                        <FaArrowDown />
                      </Button>
                    )}
                    {index > 0 && (
                      <Button
                        title="Move up"
                        size="sm"
                        variant="primary"
                        onClick={moveItem(index, true)}
                      >
                        <FaArrowUp />
                      </Button>
                    )}
                  </>
                )}
                <Button
                  title="Delete"
                  size="sm"
                  variant="danger"
                  onClick={onDeleteClick(index)}
                >
                  <FaTimes />
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="list__footer">
        <Button onClick={onAdd}>
          +
          {addText}
        </Button>
      </div>
      <Popup showPopup={showDelete}>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={onRemoveReject} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <p className="h5 mb-2">
              Are you sure you want to delete this item?
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button className="btn-danger m-0" onClick={onRemoveConfirm}>
              Delete
            </Button>
          </Col>
        </Row>
      </Popup>
    </div>
  );
};

export default List;
