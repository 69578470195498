import {
  getClassificatorValue, ReportTemplate, ReportTemplatePrefix
} from 'interfaces';
import { CompanyMap } from '../interfaces';

const disclaimer: CompanyMap = {
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.DEFISEC)]: () => null
};

export default disclaimer;
