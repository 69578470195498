import {
  Audit, Chapter, Contract, Issue,
} from 'interfaces';
import { subtractOffsetDate } from '../../../helpers';

const prepareChapter = (chapter: Chapter, index: number) => ({
  ...chapter,
  body: JSON.stringify(chapter.body),
  order: index,
});

const prepareIssue = (issue: Issue, index: number) => ({
  ...issue,
  description: JSON.stringify(issue.description),
  recommendation: JSON.stringify(issue.recommendation),
  order: index,
  comment: JSON.stringify(issue.comment),
});

const prepareContract = (contract: Contract, index: number) => ({
  ...contract,
  description: JSON.stringify(contract.description),
  order: index,
  issues: (contract?.issues || []).map(prepareIssue),
  chapters: (contract?.chapters || []).map(prepareChapter),
});

const prepareAudit = (audit: Audit) => {
  const auditAppendixes = (audit?.appendixes || []).map((item) => ({
    ...item,
    appendix: 1,
  }));
  const chapters = [
    ...(audit?.chapters || []).map(prepareChapter),
    ...auditAppendixes.map(prepareChapter),
  ];

  return {
    ...audit,
    dateEnd: subtractOffsetDate(audit.dateEnd),
    dateStart: subtractOffsetDate(audit.dateStart),
    type: audit.type === 0 ? 0 : audit.type || 2,
    description: JSON.stringify(audit.description),
    disclaimer: JSON.stringify(audit.disclaimer),
    contracts: (audit?.contracts || []).map(prepareContract),
    chapters,
    appendixes: null,
  };
};

export default prepareAudit;
