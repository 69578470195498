import React, { useEffect, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Button from 'components/Button/index';
import { Issue } from '../../../interfaces';
import { getErrorMessage } from '../../../helpers';
import Popup from '../../Popup/Popup';
import { issuesService } from '../../../services/IssuesService';
import EditIssueForm from '../../../forms/EditIssue';

interface IEditIssue {
    id: number;
    update: (
      nPage?: number,
      nLimit?: number,
      nSortBy?: string,
      nSortDirection?: string,
      nFilter?: any
  ) => Promise<void>;
    disabled: boolean;
}

const prepareIssue = (issue: any) => ({
  ...issue,
  description: issue.description ? JSON.stringify(issue.description) : null,
  recommendation: issue.recommendation ? JSON.stringify(issue.recommendation) : null,
  comment: JSON.stringify(issue.comment)
});

const parseIssue = (issue: any) => ({
  ...issue,
  description: issue.description ? JSON.parse(issue.description) : null,
  recommendation: issue.recommendation ? JSON.parse(issue.recommendation) : null,
  comment: issue.comment ? JSON.parse(issue.comment) : null,
});

const EditIssue: React.FC<IEditIssue> = ({ id, update, disabled }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isResponseLoading, setIsResponseLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [issue, setIssue] = useState<Issue | null>(null);
  const fetchIssue = async () => {
    try {
      const response = await issuesService.get(null, `${id}`);
      setIssue(parseIssue(response));
      setShowPopup(true);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    if (isResponseLoading) return;
    setIsResponseLoading(true);
    try {
      await issuesService.patch(prepareIssue(data), `${id}`);
      update();
      setShowPopup(false);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsResponseLoading(false);
    }
  };

  const onEditClose = () => {
    if (isResponseLoading) return;
    setShowPopup(false);
  };

  useEffect(() => {
    if (loading) {
      fetchIssue();
    }
  }, [loading]);

  return (
    <>
      <Button
        size="sm"
        title="Edit"
        variant="dark"
        onClick={() => setLoading(true)}
        loading={loading}
        disabled={disabled}
      >
        <FaRegEdit />
      </Button>
      <Popup showPopup={showPopup} half className="edit-issue">
        <EditIssueForm
          onSubmit={onSubmit}
          onCreateClose={onEditClose}
          state={issue!}
          isSubmitting={isResponseLoading}
        />
      </Popup>
    </>
  );
};

export default EditIssue;
