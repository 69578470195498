import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { auditService } from 'services/AuditService';
import { Audit, AuditInTable } from 'interfaces';
import { useUser } from 'providers/UserProvider';
import Table from 'components/Table/index';
import DownloadPDFReportButton from 'components/Button/DownloadReportPdfButton';
import DeleteAuditButton from 'components/Button/DeleteAuditButton';
import useQuery from 'hooks/useQuery';
import EditAuditButton from '../../Button/EditAuditButton';
import {
  dformat,
  getDataFromStorage,
  getErrorMessage,
  getExtractEnumsFactory,
  getNotEmptyValue,
  getOptionFromAuditFilter,
  getQueryParams,
  isObjectEmpty,
  setQueryParams,
} from '../../../helpers';
import AuditorsSelect from '../../AuditorsSelect/AuditorsSelect';
import ActiveAuditor from '../../ActiveAuditor/ActiveAuditor/AuditorsSelect';
import { useAuditors } from '../../../providers/AuditorsProvider';
import Input from '../../Input';
import Loader from '../../Loader';
import { EnumTypes } from '../../../services/EnumsService';
import DuplicateAuditButton from '../../Button/DuplicateAuditButton';
import { useEnums } from '../../../providers/EnumsProvider';

interface AuditTableProps {
  itemsPerPage?: number;
}

const customDropDownStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 1000,
  }),
};

const AuditTools: React.FC<{
  audit: AuditInTable;
  updateAudits: () => void;
}> = ({ audit, updateAudits }) => {
  const profile = useUser();
  const isAdmin = profile?.isAdmin;

  return (
    <div>
      {audit.id ? (
        <>
          <DownloadPDFReportButton
            size="sm"
            id={audit.id}
            name={audit.projectName}
            status={audit.status}
            short
          />
          {isAdmin && (
            <DeleteAuditButton
              id={audit.id}
              short
              updateAudits={updateAudits}
            />
          )}
          <DuplicateAuditButton audit={audit} updateAudits={updateAudits} />
          <EditAuditButton audit={audit} />
        </>
      ) : null}
    </div>
  );
};

const getEnumLabel = (enums: any[], key: any) => {
  const item = enums.find((i) => i.key === key);

  return item?.value;
};

const limitOptions = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
];

const AuditsTable: React.FC<AuditTableProps> = ({ itemsPerPage = 5 }) => {
  const query = useQuery();
  const history = useHistory();
  const dataFromQuery = getQueryParams(query);
  const user = useUser();
  const { auditors } = useAuditors();
  const { enums, filterAllEnums } = useEnums();
  const networkEnums = filterAllEnums(EnumTypes.NETWORK);
  const statusEnums = filterAllEnums(EnumTypes.AUDIT_STATUS);
  const languageEnums = filterAllEnums(EnumTypes.CODE_LANGUAGE);

  const networkOptions = useMemo(getExtractEnumsFactory(networkEnums), [enums]);
  const statusOptions = useMemo(getExtractEnumsFactory(statusEnums), [enums]);
  const auditorsOptions = useMemo(
    getExtractEnumsFactory(auditors, 'id', 'name'),
    [auditors],
  );
  const languageOptions = useMemo(getExtractEnumsFactory(languageEnums), [
    enums,
  ]);

  const [loading, setLoading] = useState<boolean>(false);
  const [pdfIdLoading, setPdfIdLoading] = useState<number>(0);
  const [audits, setAudits] = useState<Audit[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [filter, setFilter] = useState<any>(isObjectEmpty(dataFromQuery.filter) ? getDataFromStorage('filter') || {} : dataFromQuery.filter);
  const [limit, setLimit] = useState<number>(dataFromQuery.limit || getDataFromStorage('limit') || itemsPerPage);
  const [page, setPage] = useState<number>(getNotEmptyValue(dataFromQuery.page - 1, getDataFromStorage('page'), 0));
  const [sortBy, setSortBy] = useState<string>(dataFromQuery.sortBy || getDataFromStorage('sortBy') || 'dateStart');
  const [sortDirection, setSortDirection] = useState<string>(dataFromQuery.sortDirection || getDataFromStorage('sortDirection') || 'desc');

  const fetchAudits = async (
    nPage: number = page,
    nLimit: number = limit,
    nSortBy: string = sortBy,
    nSortDirection: string = sortDirection,
    nFilter: any = filter,
  ) => {
    if (user?.id) {
      setLoading(true);
      const [newAudits, count] = await auditService.userAudits(
        nPage,
        nLimit,
        nSortBy,
        nSortDirection,
        nFilter,
      );
      localStorage.setItem('tableSettings', JSON.stringify({
        page: `${nPage}`,
        limit: `${nLimit}`,
        sortBy: `${nSortBy}`,
        sortDirection: `${nSortDirection}`,
        filter: nFilter,
      }));
      history.push({
        search: setQueryParams({
          page: Number(nPage) + 1,
          limit: nLimit || limit,
          sortBy: nSortBy || sortBy,
          sortDirection: nSortDirection || sortDirection,
          filter: nFilter || filter,
        }),
      });
      setAudits(newAudits);
      setPageCount(Math.ceil(count / nLimit));
      setLoading(false);
    }
  };

  const updateAudits = async () => fetchAudits();

  useEffect(() => {
    fetchAudits();
  }, [user?.id]);

  const onLimitChange = (value: number) => {
    setPage(0);
    setLimit(value);
    fetchAudits(0, value);
  };

  const handlePageClick = (event: any) => {
    setPage(event.selected);
    fetchAudits(event.selected);
  };

  const changeSort = (sort: string, direction: string) => {
    setSortBy(sort);
    setSortDirection(direction);
    fetchAudits(page, limit, sort, direction);
  };

  let filterInputTimeout: any;
  const onFilterChange = (
    field: string,
    value: any,
    needTimeout: boolean = false,
  ) => {
    if (needTimeout && filterInputTimeout) clearTimeout(filterInputTimeout);
    filterInputTimeout = setTimeout(() => {
      const nFilter = {
        ...filter,
        [field]: value,
      };
      setPage(0);
      setFilter(nFilter);
      fetchAudits(0, limit, sortBy, sortDirection, nFilter);
    }, 500);
  };

  const openPdf = async (id: number) => {
    if (pdfIdLoading) return;
    setPdfIdLoading(id);
    try {
      await auditService.openPdf(id);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setPdfIdLoading(0);
    }
  };

  const columns = [
    {
      Header: 'ID',
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (row: AuditInTable) => (
        <div className="audit-id">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            onClick={() => {
              openPdf(row.id);
              return false;
            }}
          >
            {row.id}
          </a>
          {pdfIdLoading === row.id && <Loader size="sm" />}
        </div>
      ),
      isSortable: true,
      sortName: 'id',
      fixed: true,
      fixedLeft: true,
      fixedLeftLast: true,
      fixedValue: 0,
    },
    {
      Header: 'Start',
      accessor: (row: AuditInTable) => dformat(row.dateStart, false),
      isSortable: true,
      sortName: 'dateStart',
    },
    {
      Header: 'Active',
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (row: AuditInTable) => (row.currentUser ? (
        <ActiveAuditor
          audit={row}
          auditors={auditors}
          fetchAudits={fetchAudits}
        />
      ) : null),
      isSortable: true,
      sortName: 'currentUser',
    },
    {
      Header: 'Project',
      accessor: (row: AuditInTable) => row.projectName || 'No project name',
      minWidth: 150,
      isSortable: true,
      sortName: 'projectName',
      isFilterable: true,
      filter: (
        <Input
          onChange={(e) => onFilterChange('projectName', e.target.value, true)}
          defaultValue={filter.projectName}
        />
      ),
    },
    {
      Header: 'Status',
      accessor: (row: AuditInTable) => getEnumLabel(statusEnums, row.status),
      isSortable: true,
      sortName: 'status',
      minWidth: 200,
      isFilterable: true,
      filter: (
        <ReactSelect
          placeholder=""
          options={statusOptions}
          onChange={(e) => onFilterChange(
            'statuses',
            e.map((i) => i.value),
          )}
          isMulti
          menuPlacement="auto"
          menuPosition="fixed"
          styles={customDropDownStyles}
          value={getOptionFromAuditFilter(filter.statuses, statusOptions)}
        />
      ),
    },
    {
      Header: 'Networks',
      accessor: (row: AuditInTable) => row.networks
        .split(',')
        .map((item) => getEnumLabel(networkEnums, Number(item)))
        .join(', '),
      minWidth: 200,
      isFilterable: true,
      filter: (
        <ReactSelect
          placeholder=""
          options={networkOptions}
          onChange={(e) => onFilterChange(
            'networks',
            e.map((i) => i.value),
          )}
          isMulti
          menuPlacement="auto"
          menuPosition="fixed"
          styles={customDropDownStyles}
          value={getOptionFromAuditFilter(filter.networks, networkOptions)}
        />
      ),
    },
    {
      Header: 'Auditors',
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (row: AuditInTable) => (
        <AuditorsSelect
          audit={row}
          auditors={auditors}
          fetchAudits={fetchAudits}
        />
      ),
      minWidth: 200,
      isFilterable: true,
      filter: (
        <ReactSelect
          placeholder=""
          options={auditorsOptions}
          onChange={(e) => onFilterChange(
            'editors',
            e.map((i) => i.value),
          )}
          isMulti
          menuPlacement="auto"
          menuPosition="fixed"
          styles={customDropDownStyles}
          value={getOptionFromAuditFilter(filter.editors, auditorsOptions)}
        />
      ),
    },
    {
      Header: 'Language',
      accessor: (row: AuditInTable) => getEnumLabel(languageEnums, row.projectLanguage),
      isSortable: true,
      sortName: 'projectLanguage',
      minWidth: 200,
      isFilterable: true,
      filter: (
        <ReactSelect
          placeholder=""
          options={languageOptions}
          onChange={(e) => onFilterChange('projectLanguage', e?.value)}
          menuPlacement="auto"
          menuPosition="fixed"
          styles={customDropDownStyles}
          isClearable
          value={getOptionFromAuditFilter(filter.projectLanguage, languageOptions)}
        />
      ),
    },
    {
      Header: 'Cs',
      accessor: (row: AuditInTable) => row.contractsLength,
      isSortable: true,
      sortName: 'contractsLength',
    },
    {
      Header: 'Is',
      accessor: (row: AuditInTable) => row.issuesLength,
      isSortable: true,
      sortName: 'issuesLength',
    },
    {
      Header: 'Update',
      accessor: (row: AuditInTable) => dformat(row.userLastUpdate),
      isSortable: true,
      sortName: 'userLastUpdate',
    },
    {
      Header: 'Tools',
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (row: AuditInTable) => (
        <AuditTools audit={row} updateAudits={updateAudits} />
      ),
      minWidth: user?.isAdmin ? 186 : 146,
      fixed: true,
      fixedRightFirst: true,
      fixedValue: 0,
    },
  ];

  const scrollListener = (e: any) => {
    if (e.target.scrollLeft === 0) {
      e.target.classList.add('scroll-max-left');
    } else if (
      e.target.scrollLeft
      === e.target.scrollWidth - e.target.clientWidth
    ) {
      e.target.classList.add('scroll-max-right');
    } else {
      e.target.classList.remove('scroll-max-left');
      e.target.classList.remove('scroll-max-right');
    }
  };

  return (
    <div>
      <div
        className="table-container scroll-max-left"
        onScroll={scrollListener}
      >
        <Table
          loading={loading}
          data={audits}
          columns={columns}
          sortBy={sortBy}
          sortDirection={sortDirection}
          changeSort={changeSort}
        />
      </div>
      <div className="pagination-row">
        <ReactPaginate
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={3}
          pageCount={pageCount}
          previousLabel="< previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          forcePage={page}
          // @ts-ignore
          renderOnZeroPageCount={null}
        />
        <ReactSelect
          className="options-select"
          placeholder=""
          options={limitOptions}
          value={limitOptions.find((item) => item.value === limit)}
          onChange={(e) => onLimitChange(e?.value!)}
        />
      </div>
    </div>
  );
};

export default AuditsTable;
