import * as yup from 'yup';

import { FormFields } from 'components/Form';
import { fields as ContractFields } from 'forms/Contract';
import { fields as AppendixFields } from 'forms/Appendix';
import { fields as AuditChapterFields } from 'forms/AuditChapter';

import DateInput from 'components/DateInput';
import AuditStatusSelect from 'components/Select/AuditStatusSelect';
import AuditTypeSelect from 'components/Select/AuditTypeSelect';
import NetworkSelect from 'components/Select/NetworkSelect';
import ContractLanguageSelect from 'components/Select/ContractLanguageSelect';
import TextEditor from 'components/TextEditor';
import Contracts from 'components/List/Contracts';
import AuditChaptersList from 'components/List/AuditChapters';
import AppendixList from 'components/List/AppendixList';

const getFields = (): FormFields => {
  return {
    type: {
      label: 'Audit type',
      component: AuditTypeSelect,
    },
    status: {
      label: 'Audit status',
      component: AuditStatusSelect,
    },
    dateStart: {
      label: 'Audit start date',
      component: DateInput,
      validator: yup.date().required(),
    },
    dateEnd: {
      label: 'Audit end date',
      component: DateInput,
      validator: yup
        .date()
        .min(yup.ref('dateStart'), "end date can't be before start date"),
    },
    projectName: {
      label: 'Project name',
      validator: yup.string().required(),
    },
    projectUrl: {
      label: 'Project URL',
      validator: yup.string().url().required(),
    },
    networks: {
      label: 'Project networks',
      validator: yup.array().min(1, 'Minimum 1 network is required').required(),
      component: NetworkSelect,
    },
    projectLanguage: {
      label: 'Language',
      validator: yup.number().required(),
      component: ContractLanguageSelect,
    },
    description: {
      label: 'Overview',
      validator: yup.object().required(),
      component: TextEditor,
    },
    contracts: {
      label: 'Contracts',
      component: Contracts,
      validator: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required('Contract title is required'),
            issues: yup.array().of(
              yup.object().shape({
                name: yup.string().required('Issue name is required'),
                severity: yup.object().required('Issue severity is required')
              })
            )
          }),
        )
        .required(),
      fields: ContractFields,
    },
    chapters: {
      label: 'Chapters',
      component: AuditChaptersList,
      validator: yup.array().of(
        yup.object().shape({
          title: yup.string().required('Some text is required'),
        }),
      ),
      fields: AuditChapterFields,
    },
    appendixes: {
      label: 'Appendixes',
      component: AppendixList,
      validator: yup.array().of(
        yup.object().shape({
          title: yup.string().required('Some text is required'),
        }),
      ),
      fields: AppendixFields,
    },
  };
};

export default getFields;
