import React from 'react';
import * as yup from 'yup';

import Form, { FormField } from 'components/Form';
import { useFormContext } from 'components/Form/context';
import SubmitButton from 'components/Button/SubmitButton';
import FormFieldControl from 'components/FormFieldControl';

import { authService } from 'services/AuthService';
import { useHistory } from 'react-router';

const fields: { [x: string]: FormField } = {
  login: {
    label: 'User',
    validator: yup.string().required()
  },
  pwd: {
    label: 'Password',
    type: 'password',
    validator: yup.string().required()
  }
};

const FormContent = () => {
  const { onSubmit } = useFormContext();

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      onSubmit();
    }
  };

  return (
    <>
      <FormFieldControl name="login" onKeyDown={onKeyDown} />
      <FormFieldControl name="pwd" onKeyDown={onKeyDown} />
      <SubmitButton>Login</SubmitButton>
    </>
  );
};

const LoginForm = () => {
  const history = useHistory();
  const onSubmit = async (data: any) => {
    // check default password
    if (data.pwd === '1234') {
      history.push('/user/change-pwd');
      return;
    }
    const response = await authService.login(data);
    authService.setAccessToken(response.access_token);
    history.push('/audits');
  };

  return (
    <Form onSubmit={onSubmit} fields={fields} silent>
      <FormContent />
    </Form>
  );
};

export default LoginForm;
