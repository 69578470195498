import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { User } from 'interfaces';
import { usersService } from '../services/UsersService';

export const AuditorsContext = createContext<{
  auditors: Array<User>;
  loading: boolean;
  fetch:() => void;
    }>({
      auditors: [],
      loading: false,
      fetch: () => {},
    });

export const useAuditors = () => useContext(AuditorsContext);

const useFetchAuditors = () => {
  const [auditors, setAuditors] = useState<Array<User>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetch = useCallback(async () => {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const auditors = await usersService.companyUsers();
    setAuditors(
      auditors.sort((a: User, b: User) => a.name.localeCompare(b.name)),
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  return {
    fetch,
    loading,
    auditors,
  };
};

export const AuditorsProvider: React.FC = (props) => {
  const context = useFetchAuditors();
  const { children } = props;

  return (
    <AuditorsContext.Provider value={context}>
      {children}
    </AuditorsContext.Provider>
  );
};
