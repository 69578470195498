import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import ReactPaginate from 'react-paginate';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { FaRegPlusSquare } from 'react-icons/fa';
import {
  SelectOption,
  Severity,
  StandardIssue,
  StandardIssueInTable
} from 'interfaces';
import { useUser } from 'providers/UserProvider';
import Table from 'components/Table/index';
import { severityService } from 'services/SeverityService';
import {
  dformat,
  getErrorMessage,
  getExtractEnumsFactory,
} from '../../../helpers';
import Input from '../../Input';
import { EnumTypes } from '../../../services/EnumsService';
import { useEnums } from '../../../providers/EnumsProvider';
import { standardIssuesService } from '../../../services/StandardIssuesService';
import Button from '../../Button';
import Popup from '../../Popup/Popup';
import StandardIssueForm from '../../../forms/StandardIssue';
import EditStandardIssueButton from '../../Button/EditStandardIssueButton';
import SelectStandardIssueButton from '../../Button/SelectStandardIssueButton';
import DeleteStandardIssueButton from '../../Button/DeleteStandardIssueButton';

interface IssueTableProps {
  itemsPerPage?: number;
  selectMode?: boolean;
  onSelect?: (data: StandardIssue) => void;
}

const customDropDownStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 1000,
  }),
};

const getEnumLabel = (enums: any[], key: any) => {
  const item = enums.find((i) => i.key === key);

  return item?.value;
};

const limitOptions = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
];

const prepareIssue = (issue: any) => ({
  ...issue,
  description: JSON.stringify(issue.description),
});

const StandardIssuesTable: React.FC<IssueTableProps> = ({
  itemsPerPage = 5,
  selectMode = false,
  onSelect = () => {},
}) => {
  const tableWrapperElement = useRef(null);
  const user = useUser();
  const isAdmin = user?.isAdmin;
  const { enums, filterAllEnums } = useEnums();
  const contractTagsEnums = filterAllEnums(EnumTypes.CONTRACT_TAGS);
  const contractTypeEnums = filterAllEnums(EnumTypes.CONTRACT_TYPE);

  const contractTypeOptions = useMemo(
    getExtractEnumsFactory(contractTypeEnums),
    [enums],
  );
  const contractTagsOptions = useMemo(
    getExtractEnumsFactory(contractTagsEnums),
    [enums],
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [issues, setIssues] = useState<StandardIssueInTable[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [filter, setFilter] = useState<any>({});
  const [limit, setLimit] = useState<number>(itemsPerPage);
  const [page, setPage] = useState<number>(0);
  const [severityOptions, setSeverityOptions] = useState<SelectOption[]>();
  const [sortBy, setSortBy] = useState<string>('createdAt');
  const [sortDirection, setSortDirection] = useState<string>('desc');
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [isResponseLoading, setIsResponseLoading] = useState<boolean>(false);

  const fetchSeverity = async () => {
    try {
      const severity = await severityService.getSeverityList();
      setSeverityOptions(severity.map((item: Severity) => ({
        value: item,
        label: item.name,
        isDisabled: item.isDeleted,
      })));
    } catch (e) {
      toast.error(`Fetch severity error. ${getErrorMessage(e)}`);
    }
  };

  const fetchIssues = async (
    nPage: number = page,
    nLimit: number = limit,
    nSortBy: string = sortBy,
    nSortDirection: string = sortDirection,
    nFilter: any = filter,
  ) => {
    if (user?.id) {
      setLoading(true);
      const [newIssues, count] = await standardIssuesService.getList(
        nPage,
        nLimit,
        nSortBy,
        nSortDirection,
        nFilter,
      );
      setIssues(newIssues);
      setPageCount(Math.ceil(count / nLimit));
      setLoading(false);
    }
  };

  const updateIssues = () => fetchIssues();

  useEffect(() => {
    fetchIssues();
  }, [user?.id]);

  const onLimitChange = (value: number) => {
    setPage(0);
    setLimit(value);
    fetchIssues(0, value);
  };

  const handlePageClick = (event: any) => {
    setPage(event.selected);
    fetchIssues(event.selected);
  };

  const changeSort = (sort: string, direction: string) => {
    setSortBy(sort);
    setSortDirection(direction);
    fetchIssues(page, limit, sort, direction);
  };

  let filterInputTimeout: any;
  const onFilterChange = (
    field: string,
    value: any,
    needTimeout: boolean = false,
  ) => {
    if (needTimeout && filterInputTimeout) clearTimeout(filterInputTimeout);
    filterInputTimeout = setTimeout(() => {
      const nFilter = {
        ...filter,
        [field]: value,
      };
      setPage(0);
      setFilter(nFilter);
      fetchIssues(0, limit, sortBy, sortDirection, nFilter);
    }, 500);
  };

  const onSubmit = async (data: any) => {
    if (isResponseLoading) return;
    setIsResponseLoading(true);
    try {
      await standardIssuesService.post(prepareIssue(data));
      toast.success('Issue saved');
      fetchIssues();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsResponseLoading(false);
      setShowCreate(false);
    }
  };

  const onCreateClose = () => {
    if (isResponseLoading) return;
    setShowCreate(false);
  };

  const columns = [
    {
      Header: 'Created',
      accessor: (row: StandardIssueInTable) => dformat(row.createdAt, false),
      isSortable: true,
      sortName: 'createdAt',
    },
    {
      Header: 'Contract type',
      accessor: (row: StandardIssueInTable) => getEnumLabel(contractTypeEnums, row.contractType),
      isSortable: true,
      sortName: 'contractType',
      isFilterable: true,
      filter: (
        <ReactSelect
          placeholder=""
          options={contractTypeOptions}
          onChange={(e) => onFilterChange(
            'contractType',
            e.map((i) => i.value),
          )}
          isMulti
          menuPlacement="auto"
          menuPosition="fixed"
          styles={customDropDownStyles}
        />
      ),
    },
    {
      Header: 'Title',
      accessor: (row: StandardIssueInTable) => row.name,
      minWidth: 150,
      isSortable: true,
      sortName: 'name',
      isFilterable: true,
      filter: (
        <Input onChange={(e) => onFilterChange('name', e.target.value, true)} />
      ),
    },
    {
      Header: 'Severity',
      accessor: (row: StandardIssueInTable) => {
        return severityOptions?.find((severity: SelectOption) => severity.value.id === row.severity)?.label;
      },
      isSortable: true,
      sortName: 'severity',
      isFilterable: true,
      filter: (
        <ReactSelect
          placeholder=""
          options={severityOptions}
          onChange={(e) => onFilterChange(
            'severity',
            e.map((i) => i.value.id),
          )}
          isMulti
          menuPlacement="auto"
          menuPosition="fixed"
          styles={customDropDownStyles}
        />
      ),
    },
    {
      Header: 'Tags',
      accessor: (row: StandardIssueInTable) => (row.tags || '')
        .split(',')
        .map((item) => getEnumLabel(contractTagsEnums, Number(item)))
        .join(', '),
      minWidth: 200,
      isFilterable: true,
      filter: (
        <ReactSelect
          placeholder=""
          options={contractTagsOptions}
          onChange={(e) => onFilterChange(
            'tags',
            e.map((i) => i.value),
          )}
          isMulti
          menuPlacement="auto"
          menuPosition="fixed"
          styles={customDropDownStyles}
        />
      ),
    },
    {
      Header: 'Tools',
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (row: StandardIssueInTable) => (
        <div>
          {!selectMode && (
          <>
            <EditStandardIssueButton id={row.id} update={updateIssues} />
            {isAdmin && <DeleteStandardIssueButton id={row.id} isDeleted={row.isDeleted} update={updateIssues} />}
          </>
          )}
          {selectMode && (
            <SelectStandardIssueButton id={row.id} onSelect={onSelect} />
          )}
        </div>
      ),
      exactWidth: 105,
      fixed: true,
      fixedRightFirst: true,
      fixedValue: 0,
    },
  ];

  const scrollListener = (e: any) => {
    if (e.target.scrollLeft === 0) {
      e.target.classList.add('scroll-max-left');
    } else if (
      e.target.scrollLeft
      === e.target.scrollWidth - e.target.clientWidth
    ) {
      e.target.classList.add('scroll-max-right');
    } else {
      e.target.classList.remove('scroll-max-left');
      e.target.classList.remove('scroll-max-right');
    }
  };

  useEffect(() => {
    fetchSeverity();
  }, []);

  return (
    <div>
      <div
        ref={tableWrapperElement}
        // @ts-ignore
        className={`table-container ${tableWrapperElement?.current?.scrollWidth > tableWrapperElement?.current?.clientWidth
          ? ''
          : 'scroll-max-right'
        }`}
        onScroll={scrollListener}
      >
        <Table
          loading={loading}
          data={issues}
          columns={columns}
          sortBy={sortBy}
          sortDirection={sortDirection}
          changeSort={changeSort}
        />
      </div>
      <div className="pagination-row">
        <ReactPaginate
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={3}
          pageCount={pageCount}
          previousLabel="< previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          forcePage={page}
          // @ts-ignore
          renderOnZeroPageCount={null}
        />
        <ReactSelect
          className="options-select"
          placeholder=""
          options={limitOptions}
          value={limitOptions.find((item) => item.value === limit)}
          onChange={(e) => onLimitChange(e?.value!)}
        />
      </div>
      {!selectMode ? (
        <>
          <Button
            className="add-new-btn"
            variant="primary"
            onClick={() => setShowCreate(true)}
          >
            <FaRegPlusSquare />
            {' '}
            Add new
          </Button>
          <Popup showPopup={showCreate} half>
            <StandardIssueForm
              onSubmit={onSubmit}
              onCreateClose={onCreateClose}
              isSubmitting={isResponseLoading}
            />
          </Popup>
        </>
      ) : null}
    </div>
  );
};

export default StandardIssuesTable;
