import React from 'react';
import Page from 'containers/page';
import LoginForm from 'forms/Login';

import { authService } from 'services/AuthService';
import { Redirect } from 'react-router';

const Login = () => {
  const accessToken = authService.getAccessToken();
  if (accessToken) return <Redirect to="/user/profile" />;
  return (
    <Page title="Login">
      <LoginForm />
    </Page>
  );
};

export default Login;
