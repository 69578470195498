import React, {
  useCallback, useMemo, useState, useEffect
} from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useHistory } from 'react-router';

import { Audit } from 'interfaces';

import { useUser } from 'providers/UserProvider';

import { auditService } from 'services/AuditService';

import Form from 'components/Form';
import SubmitButton from 'components/Button/SubmitButton';
import FormFieldControl from 'components/FormFieldControl';

import './Audit.scss';

import { useFormContext } from 'components/Form/context';
import { CompanyMap } from './interfaces';
import getDefaultAudit from './helpers/getDefaultAudit';
import getFields from './helpers/getFields';
import prepareAudit from './helpers/prepareAudit';
import Button, { ButtonProps } from '../../components/Button';

import Chapters from './fields/Chapters';
import Disclaimer from './fields/Disclaimer';
import Type from './fields/Type';

interface AuditFormContentProps {
  audit?: Audit;
}

interface AuditFormProps {
  audit?: Audit;
  onChange?: () => void;
}

interface ViewDocumentButtonProps extends ButtonProps {
  hotkeyAllow?: boolean;
}

const ViewDocumentButton: React.FC<Omit<ViewDocumentButtonProps, 'type'>> = ({ hotkeyAllow, ...props }) => {
  const { getValues } = useFormContext();
  const [loading, setLoading] = useState<boolean>(false);
  const viewResult = async () => {
    if (loading) return false;
    try {
      setLoading(true);
      const currentData = getValues();
      await auditService.previewDocument(currentData.id, prepareAudit(currentData));
    } catch (e) {
      // do nothing
    } finally {
      setLoading(false);
    }

    return false;
  };

  const onKeydown = (e: KeyboardEvent) => {
    if ((e.code === 'KeyP' || e.code === 'Digit3') && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      viewResult();
    }
  };

  useEffect(() => {
    if (hotkeyAllow) {
      document.addEventListener('keydown', onKeydown);
    }

    return () => {
      if (hotkeyAllow) {
        document.removeEventListener('keydown', onKeydown);
      }
    };
  }, []);

  return <Button onClick={viewResult} loading={loading} {...props} />;
};

const AuditFormContent: React.FC<AuditFormContentProps> = ({ audit }) => {
  const profile = useUser();
  const {
    onSubmit, collapseAll
  } = useFormContext();

  if (!audit) {
    // eslint-disable-next-line no-param-reassign
    audit = getDefaultAudit(profile);
  }

  const onKeydown = (e: KeyboardEvent) => {
    if (e.code === 'KeyS' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      onSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);

    return () => document.removeEventListener('keydown', onKeydown);
  }, []);

  const Field = (Component: CompanyMap) => useMemo(() => {
    if (!profile) {
      return null;
    }

    return Component[profile?.reportType];
  }, [profile]);

  const DisclaimerField = Field(Disclaimer);
  const TypeField = Field(Type);
  const ChaptersField = Field(Chapters);

  return (
    <>
      <Card>
        <Card.Body>
          {DisclaimerField && <DisclaimerField audit={audit} />}
          <Row>
            {TypeField && <TypeField audit={audit} />}
            {audit.id && (
              <Col>
                <FormFieldControl name="status" defaultValue={audit.status} />
              </Col>
            )}
            <Col>
              <FormFieldControl name="dateStart" defaultValue={audit.dateStart} />
            </Col>
            <Col>
              <FormFieldControl name="dateEnd" defaultValue={audit.dateEnd} />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormFieldControl name="projectName" />
            </Col>
            <Col>
              <FormFieldControl name="projectUrl" />
            </Col>
            <Col>
              <FormFieldControl name="networks" defaultValue={audit.networks} />
            </Col>
            <Col>
              <FormFieldControl name="projectLanguage" defaultValue={audit.projectLanguage} />
            </Col>
          </Row>
          {audit?.externalUrl && (
            <div className="d-flex justify-content-end mb-3">
              <a href={audit?.externalUrl} target="_blank" rel="noopener noreferrer">
                Report
              </a>
            </div>
          )}
          <Row>
            <Col>
              <FormFieldControl name="description" defaultValue={audit.description} />
            </Col>
          </Row>
          {ChaptersField && <ChaptersField audit={audit} />}
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <FormFieldControl name="contracts" />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <FormFieldControl name="appendixes" />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className="fixed-footer">
        <div>
          {!audit.id || audit.currentUser?.id === profile?.id ? (
            <SubmitButton>Save</SubmitButton>
          ) : (
            <div className="fixed-footer__no-actions">No actions available</div>
          )}
          {audit.currentUser?.id === profile?.id ? (
            <>
              <SubmitButton id="submit-exit">Save & Exit</SubmitButton>
              <ViewDocumentButton hotkeyAllow>Preview (unsaved)</ViewDocumentButton>
            </>
          ) : null}
        </div>
        <Button onClick={collapseAll}>Minimize all</Button>
      </div>
    </>
  );
};

const AuditForm: React.FC<AuditFormProps> = ({ audit, onChange }) => {
  const history = useHistory();
  const profile = useUser();

  if (!audit) {
    // eslint-disable-next-line no-param-reassign
    audit = getDefaultAudit(profile);
  }

  const fields = useMemo(() => getFields(), [profile]);

  const onSubmit = useCallback(
    async (data: Audit, exit: boolean) => {
      let response: any;
      if (data.id) {
        response = await auditService.patch(prepareAudit(data), `${data.id}${exit ? '?unassign=true' : ''}`);
        if (exit) {
          history.push('/audits');
        } else {
          // eslint-disable-next-line no-unused-expressions
          onChange && onChange();
        }
      } else {
        response = await auditService.post(prepareAudit(data));
      }

      if (!exit && response.id) {
        history.push(`/audits/edit/${response.id}`);
      }
    },
    [profile?.id]
  );

  return (
    <Form fields={fields} onSubmit={onSubmit} defaultValues={audit} prompt>
      <AuditFormContent audit={audit} />
    </Form>
  );
};

export default AuditForm;
