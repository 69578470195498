import React, { useCallback, useState, useEffect } from 'react';
import { FaDownload } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { auditService } from 'services/AuditService';

import Button from 'components/Button/index';
import { getErrorMessage } from 'helpers';
import { AuditStatus } from 'interfaces';

interface DownloadPDFReportButtonProps {
  id: number;
  short?: boolean;
  name: string;
  status?: AuditStatus;
  size?: 'sm' | 'lg';
  hotkeyAllow?: boolean;
}

const prepareName = (name: string) => name.replaceAll(/\s/g, '-');

const getFilename = (
  name: string,
) => {
  const preparedName = prepareName(name);
  return `${preparedName}_final-audit-report_${Date.now()}.pdf`;
};

const DownloadPDFReportButton: React.FC<DownloadPDFReportButtonProps> = ({
  id,
  short,
  name,
  status,
  size,
  hotkeyAllow
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const downloadPDF = useCallback(async () => {
    setLoading(true);
    try {
      await auditService.downloadPdf(
        id,
        getFilename(name),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [id, name, status]);

  const onKeydown = async (e: KeyboardEvent) => {
    if ((e.code === 'KeyD' || e.code === 'Digit4') && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      await downloadPDF();
    }
  };

  useEffect(() => {
    if (hotkeyAllow) {
      document.addEventListener('keydown', onKeydown);
    }

    return () => {
      if (hotkeyAllow) {
        document.removeEventListener('keydown', onKeydown);
      }
    };
  }, [name]);

  return (
    <Button
      size={size}
      title={short ? 'Download PDF' : undefined}
      variant="success"
      onClick={downloadPDF}
      loading={loading}
    >
      <FaDownload />
      {' '}
      {short ? null : 'Download PDF'}
    </Button>
  );
};

export default DownloadPDFReportButton;
