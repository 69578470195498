import React, { useState } from 'react';
import {
  ListGroup, Row, Col, CloseButton,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  FaEdit,
  FaRegPlusSquare,
  FaSync,
  FaUndo,
  FaUserLock,
} from 'react-icons/fa';
import Button from '../../components/Button';
import Popup from '../../components/Popup/Popup';
import { getErrorMessage } from '../../helpers';
import Input from '../../components/Input';
import { useAuditors } from '../../providers/AuditorsProvider';
import { User } from '../../interfaces';
import { usersService } from '../../services/UsersService';

const UsersPanel: React.FC = () => {
  const { auditors, fetch } = useAuditors();

  const [showReset, setShowReset] = useState<boolean>(false);
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [newUser, setNewUser] = useState<{
    login?: string;
    name: string;
    isAdmin?: boolean;
  }>({
    login: '',
    name: '',
    isAdmin: false,
  });
  const [isResponseLoading, setIsResponseLoading] = useState<boolean>(false);

  const blockUser = async () => {
    if (isResponseLoading) return;
    setIsResponseLoading(true);
    try {
      await usersService.blockUser(selectedUser!.id, selectedUser!.blocked);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onBlockClose(true);
      fetch();
      toast.success(`User ${selectedUser!.blocked ? 'unblocked' : 'blocked'}`);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsResponseLoading(false);
    }
  };

  const resetPwd = async () => {
    if (isResponseLoading) return;
    if (!selectedUser) return;
    setIsResponseLoading(true);
    try {
      await usersService.resetPwd(selectedUser!.id);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onResetClose(true);
      fetch();
      setNewUser({
        login: '',
        name: '',
        isAdmin: false,
      });
      toast.success('Password reset');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsResponseLoading(false);
    }
  };

  const createItem = async () => {
    if (isResponseLoading) return;
    if (!newUser.login || !newUser.name) return;
    setIsResponseLoading(true);
    try {
      await usersService.createUser(
        newUser.login,
        newUser.name,
        newUser.isAdmin!,
      );
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onCreateClose(true);
      fetch();
      setNewUser({
        login: '',
        name: '',
        isAdmin: false,
      });
      toast.success('User saved');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsResponseLoading(false);
    }
  };

  const editItem = async () => {
    if (isResponseLoading) return;
    if (!newUser.name) return;
    setIsResponseLoading(true);
    try {
      await usersService.updateUser(selectedUser!.id, newUser.name);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onCreateClose(true);
      fetch();
      setNewUser({
        login: '',
        name: '',
        isAdmin: false,
      });
      toast.success('User updated');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsResponseLoading(false);
    }
  };

  const onBlockConfirm = (auditor: User) => {
    setShowBlock(true);
    setSelectedUser(auditor);
  };

  const onBlockClose = (force = false) => {
    if (!force && isResponseLoading) return;
    setShowBlock(false);
    setSelectedUser(null);
  };

  const onResetClose = (force = false) => {
    if (!force && isResponseLoading) return;
    setShowReset(false);
    setSelectedUser(null);
  };

  const onCreateClose = (force = false) => {
    if (!force && isResponseLoading) return;
    setShowCreate(false);
    setNewUser({
      login: '',
      name: '',
      isAdmin: false,
    });
    setSelectedUser(null);
  };

  const onNewUserTyped = (field: string, value: any) => {
    setNewUser({
      ...newUser,
      [field]: value,
    });
  };

  const onEditConfirm = (auditor: User) => {
    setShowCreate(true);
    setNewUser({
      name: auditor.name,
    });
    setSelectedUser(auditor);
  };

  const onResetConfirm = (auditor: User) => {
    setShowReset(true);
    setSelectedUser(auditor);
  };

  return (
    <>
      <ListGroup>
        {auditors.map((auditor) => (
          <ListGroup.Item key={auditor.id} className="list-group-item__user">
            {auditor.name}
            <Button
              className="item-btn item-btn_disable"
              title={auditor.blocked ? 'Unblock user' : 'Block user'}
              size="sm"
              variant={auditor.blocked ? 'primary' : 'danger'}
              onClick={() => onBlockConfirm(auditor)}
            >
              {auditor.blocked ? <FaUndo /> : <FaUserLock />}
            </Button>
            <Button
              className="item-btn item-btn_edit"
              title="Edit"
              size="sm"
              variant="secondary"
              onClick={() => onEditConfirm(auditor)}
            >
              <FaEdit />
            </Button>
            <Button
              className="item-btn item-btn_reset-pwd"
              title="Reset password"
              size="sm"
              variant="success"
              onClick={() => onResetConfirm(auditor)}
            >
              <FaSync />
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <Button
        className="add-new-btn"
        variant="primary"
        onClick={() => setShowCreate(true)}
      >
        <FaRegPlusSquare />
        {' '}
        Add new
      </Button>
      <Popup showPopup={showReset}>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={() => onResetClose()} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <p className="h5 mb-2">Are you sure you want to reset password?</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              className="btn-danger m-0"
              onClick={resetPwd}
              loading={isResponseLoading}
            >
              Reset password
            </Button>
          </Col>
        </Row>
      </Popup>
      <Popup showPopup={showBlock}>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={() => onBlockClose()} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <p className="h5 mb-2">
              {`Do you want to ${
                selectedUser?.blocked ? 'unblock' : 'block'
              } this user?`}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              className={`btn-${selectedUser?.blocked ? 'success' : 'danger'} m-0`}
              onClick={blockUser}
              loading={isResponseLoading}
            >
              {selectedUser?.blocked ? 'Unblock' : 'Block'}
            </Button>
          </Col>
        </Row>
      </Popup>
      <Popup showPopup={showCreate}>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={() => onCreateClose()} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <div className="create-text-wrapper">
              { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
              <label htmlFor="new-user-name">Name</label>
              <Input
                id="new-user-name"
                value={newUser.name}
                onChange={(e) => onNewUserTyped('name', e.target.value)}
              />
            </div>
            {!selectedUser?.id ? (
              <>
                <div className="create-text-wrapper">
                  { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                  <label htmlFor="new-user-login">Login</label>
                  <Input
                    id="new-user-login"
                    value={newUser.login}
                    onChange={(e) => onNewUserTyped('login', e.target.value)}
                  />
                </div>
                <div className="create-text-wrapper">
                  { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                  <label htmlFor="new-user-is-admin">Is admin</label>
                  <input
                    type="checkbox"
                    id="new-user-is-admin"
                    checked={newUser.isAdmin}
                    onChange={() => onNewUserTyped('isAdmin', !newUser.isAdmin)}
                  />
                </div>
              </>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              className="btn-success m-0"
              onClick={!selectedUser?.id ? createItem : editItem}
              disabled={(!selectedUser?.id && !newUser.login) || !newUser.name}
              loading={isResponseLoading}
            >
              {!selectedUser?.id ? 'Create' : 'Edit'}
            </Button>
          </Col>
        </Row>
      </Popup>
    </>
  );
};

export default UsersPanel;
