import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  getClassificatorValue, Issue, ReportTemplate, ReportTemplatePrefix
} from 'interfaces';
import FormFieldControl from 'components/FormFieldControl';

interface CompanyMap {
  [key: number]: (props: any) => JSX.Element | null;
}

interface RecomendationProps {
  state: Issue;
}

const RecomendationField: React.FC<RecomendationProps> = ({ state }) => {
  const { recommendation } = state;

  return (
    <Row>
      <Col>
        <FormFieldControl name="recommendation" defaultValue={recommendation} />
      </Col>
    </Row>
  );
};

const recomendation: CompanyMap = {
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.DEFISEC)]: RecomendationField
};

export default recomendation;
