import React, { useEffect } from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch, useHistory
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from 'state';
import { UserProvider, useUser } from 'providers/UserProvider';
import { authService } from 'services/AuthService';
import Audits from 'views/audits';
import AddAudit from 'views/audits/add';
import EditAudit from 'views/audits/edit';
import PreviewAudit from 'views/audits/preview';
import Login from 'views/user/login';
import Logout from 'views/user/logout';
import Profile from 'views/user/profile';
import Header from 'components/Header';
import { AuditorsProvider } from './providers/AuditorsProvider';
import Admin from './views/admin';
import './App.css';
import ChangePwd from './views/user/change-pwd';
import Issues from './views/issues';
import StandardIssues from './views/standard-issues';
import Help from './views/help';
import { EnumsProvider } from './providers/EnumsProvider';
import ForkChecker from './views/fork-checker';

const ProtectedApp: React.FC<any> = ({ children }) => {
  const accessToken = authService.getAccessToken();
  const history = useHistory();

  const onKeydown = (e: KeyboardEvent) => {
    if (e.code === 'KeyL' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      history.push('/user/logout');
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);

    return () => document.removeEventListener('keydown', onKeydown);
  }, []);

  if (!accessToken) return <Redirect to="/user/login" />;

  return (
    <EnumsProvider>
      <AuditorsProvider>
        <UserProvider>
          <Header />
          {children}
        </UserProvider>
      </AuditorsProvider>
    </EnumsProvider>
  );
};

const ProtectedRoutes: React.FC<any> = () => {
  const profile = useUser();

  return (
    <Switch>
      <Route exact path="/audits">
        <Audits />
      </Route>
      <Route exact path="/issues">
        <Issues />
      </Route>
      <Route exact path="/standard-issues">
        <StandardIssues />
      </Route>
      {profile?.isAdmin ? (
        <Route exact path="/admin">
          <Admin />
        </Route>
      ) : null}
      <Route exact path="/audits/add">
        <AddAudit />
      </Route>
      <Route exact path="/audits/edit/:id">
        <EditAudit />
      </Route>
      <Route exact path="/audits/preview/:id">
        <PreviewAudit />
      </Route>
      <Route exact path="/fork-checker">
        <ForkChecker />
      </Route>
      <Route exact path="/user/profile">
        <Profile />
      </Route>
      <Route exact path="/help">
        <Help />
      </Route>
      <Redirect to="/audits" />
    </Switch>
  );
};

const App = () => (
  <Provider store={store}>
    <div className="app">
      <Router>
        <Switch>
          <Route path="/user/login">
            <Login />
          </Route>
          <Route path="/user/change-pwd">
            <ChangePwd />
          </Route>
          <Route path="/user/login">
            <Login />
          </Route>
          <Route path="/user/logout">
            <Logout />
          </Route>
          <ProtectedApp>
            <ProtectedRoutes />
          </ProtectedApp>
          <Redirect to="/user/profile" />
        </Switch>
      </Router>
      <ToastContainer />
    </div>
  </Provider>
);

export default App;
