import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { auditService } from 'services/AuditService';

import Page from 'containers/page';
import PDFPreview from 'components/PDFPreview';
import Loader from 'components/Loader';

const Preview = () => {
  const { id } = useParams<{ id: string }>();
  const [file, setFile] = useState<any>(null);
  useEffect(() => {
    const fetchAuditPreview = async (auditId: string) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const file = await auditService.downloadPreview(parseInt(auditId));
      setFile(file);
    };

    fetchAuditPreview(id);
  }, [id]);
  return (
    <Page title="Preview">
      {!file ? <Loader /> : <PDFPreview file={file} />}
    </Page>
  );
};

export default Preview;
