import React, {
  useMemo,
  useRef,
  useCallback,
  useState,
  useEffect
} from 'react';
import ReactSelect from 'react-select';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { IssueInTable, SelectOption, Severity } from 'interfaces';
import Table from 'components/Table';
import EditIssueButton from 'components/Button/EditIssueButton';
import { toast } from 'react-toastify';
import { severityService } from 'services/SeverityService';
import Input from '../../Input';
import { useEnums } from '../../../providers/EnumsProvider';
import { useUser } from '../../../providers/UserProvider';
import { EnumTypes } from '../../../services/EnumsService';
import { getErrorMessage, getExtractEnumsFactory } from '../../../helpers';

import './index.css';

interface IssuesTableProps {
  loading: boolean;
  issues: IssueInTable[];
  fetchIssues: (
    nPage?: number,
    nLimit?: number,
    nSortBy?: string,
    nSortDirection?: string,
    nFilter?: any
) => Promise<void>;
  sortBy: string;
  sortDirection: string;
  changeSort: (sort: string, direction: string) => void;
  onFilterChange: (field: string, value: any, needTimeout?: boolean) => void;
  page: number;
  pageCount: number;
  handlePageClick: (e: { selected: number }) => void;
  limit: number;
  onLimitChange: (value: number) => void;
}

const getEnumLabel = (enums: any[], key: any) => {
  const item = enums.find((i) => i.key === key);

  return item?.value;
};

const limitOptions = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
];

const isEditButtonDisabled = (editors: string, userId: number | undefined, currentUser: number | null) => !(editors.split(',').includes(String(userId)) && currentUser === null);

const IssuesTable: React.FC<IssuesTableProps> = ({
  loading,
  fetchIssues,
  issues,
  sortBy,
  sortDirection,
  changeSort,
  onFilterChange,
  page,
  pageCount,
  handlePageClick,
  limit,
  onLimitChange,
}) => {
  const tableWrapperElement = useRef<any>();
  const { enums, filterAllEnums } = useEnums();
  const contractTypeEnums = filterAllEnums(EnumTypes.CONTRACT_TYPE);
  const resolutionEnums = filterAllEnums(EnumTypes.ISSUE_STATUS);
  const [severityOptions, setSeverityOptions] = useState<SelectOption[]>();

  const user = useUser();

  const contractTypeOptions = useMemo(
    getExtractEnumsFactory(contractTypeEnums),
    [enums],
  );
  const resolutionOptions = useMemo(getExtractEnumsFactory(resolutionEnums), [
    enums,
  ]);
  const isStandardOptions = [
    { label: 'true', value: true },
    { label: 'false', value: false },
  ];

  const fetchSeverity = async () => {
    try {
      const severity = await severityService.getSeverityList();
      setSeverityOptions(severity.map((item: Severity) => ({
        value: item,
        label: item.name,
        isDisabled: item.isDeleted,
      })));
    } catch (e) {
      toast.error(`Fetch severity error. ${getErrorMessage(e)}`);
    }
  };

  useEffect(() => {
    fetchSeverity();
  }, []);

  const columns = [
    {
      Header: 'ID',
      accessor: (row: IssueInTable) => row.id,
      isSortable: true,
      sortName: 'id'
    },
    {
      Header: 'Project',
      accessor: useCallback((row: IssueInTable) => (
        <Link to={`/audits/edit/${row.projectId}`}>
          {row.projectName}
        </Link>
      ), []),
      isSortable: true,
      sortName: 'projectName',
      isFilterable: true,
      filter: (
        <Input
          onChange={(e) => onFilterChange('projectName', e.target.value, true)}
        />
      )
    },
    {
      Header: 'Contract',
      accessor: (row: IssueInTable) => row.contractName,
      isSortable: true,
      sortName: 'contractName',
      isFilterable: true,
      filter: (
        <Input
          onChange={(e) => onFilterChange('contractName', e.target.value, true)}
        />
      )
    },
    {
      Header: 'Contract type',
      accessor: (row: IssueInTable) => getEnumLabel(contractTypeEnums, row.contractType),
      isSortable: true,
      sortName: 'contractType',
      isFilterable: true,
      filter: (
        <ReactSelect
          placeholder=""
          options={contractTypeOptions}
          onChange={(e) => onFilterChange(
            'contractType',
            e.map((i) => i.value)
          )}
          isMulti
          menuPlacement="auto"
          menuPosition="fixed"
        />
      )
    },
    {
      Header: 'Issue title',
      accessor: (row: IssueInTable) => row.name,
      isSortable: true,
      sortName: 'name',
      isFilterable: true,
      filter: (
        <Input
          onChange={(e) => onFilterChange('name', e.target.value, true)}
        />
      )
    },
    {
      Header: 'Severity',
      accessor: (row: IssueInTable) => {
        return severityOptions?.find((severity: SelectOption) => severity.value.id === row.severity)?.label;
      },
      minWidth: 150,
      isSortable: true,
      sortName: 'severity',
      isFilterable: true,
      filter: (
        <ReactSelect
          placeholder=""
          options={severityOptions}
          onChange={(e) => onFilterChange(
            'severity',
            e.map((i) => i.value.id)
          )}
          isMulti
          menuPlacement="auto"
          menuPosition="fixed"
        />
      )
    },
    {
      Header: 'Status',
      accessor: (row: IssueInTable) => getEnumLabel(resolutionEnums, row.resolution),
      minWidth: 150,
      isSortable: true,
      sortName: 'resolution',
      isFilterable: true,
      filter: (
        <ReactSelect
          placeholder=""
          options={resolutionOptions}
          onChange={(e) => onFilterChange(
            'resolution',
            e.map((i) => i.value)
          )}
          isMulti
          menuPlacement="auto"
          menuPosition="fixed"
        />
      )
    },
    {
      Header: 'Is standard',
      accessor: useCallback(
        (row: IssueInTable) => (
          <div className="checkbox-center">
            <input type="checkbox" className="checkbox" checked={row.standard} disabled />
            { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="checkbox-label" />
          </div>
        ),
        []
      ),
      isSortable: true,
      sortName: 'standard',
      isFilterable: true,
      filter: (
        <ReactSelect
          placeholder=""
          options={isStandardOptions}
          onChange={(e) => onFilterChange('standard', e?.value)}
          menuPlacement="auto"
          menuPosition="fixed"
          isClearable
        />
      ),
    },
    {
      Header: 'Tools',
      accessor: useCallback((row: IssueInTable) => <EditIssueButton id={row.id} update={fetchIssues} disabled={isEditButtonDisabled(row.editors, user?.id, row.currentUser)} />, []),
      exactWidth: 50,
      fixed: true,
      fixedRightFirst: true,
      fixedValue: 0,
    }
  ];

  const scrollListener = (e: any) => {
    if (e.target.scrollLeft === 0) {
      e.target.classList.add('scroll-max-left');
    } else if (
      e.target.scrollLeft
      === e.target.scrollWidth - e.target.clientWidth
    ) {
      e.target.classList.add('scroll-max-right');
    } else {
      e.target.classList.remove('scroll-max-left');
      e.target.classList.remove('scroll-max-right');
    }
  };

  return (
    <>
      <div
        ref={tableWrapperElement}
        className={`table-container ${
          tableWrapperElement?.current?.scrollWidth
          > tableWrapperElement?.current?.clientWidth
            ? ''
            : 'scroll-max-right'
        }`}
        onScroll={scrollListener}
      >
        <Table
          loading={loading}
          data={issues}
          columns={columns}
          sortBy={sortBy}
          sortDirection={sortDirection}
          changeSort={changeSort}
        />
      </div>

      <div className="pagination-row">
        {pageCount > 1 && (
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={3}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            forcePage={page}
            renderOnZeroPageCount={undefined}
          />
        )}

        <ReactSelect
          className="options-select"
          placeholder=""
          options={limitOptions}
          value={limitOptions.find((item) => item.value === limit)}
          onChange={(e) => onLimitChange(e?.value!)}
        />
      </div>
    </>
  );
};

export default IssuesTable;
