import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';

import {
  getClassificatorValue,
  Issue,
  Profile,
  ReportTemplate,
  ReportTemplatePrefix,
} from 'interfaces';

import { fields as CommentFields } from 'forms/Comment';

import { FormFields } from 'components/Form';
import FormFieldControl from 'components/FormFieldControl';
import TextEditor from 'components/TextEditor';

import SeveritySelect from 'components/Select/SeveritySelect';
import ResolutionSelect from 'components/Select/ResolutionSelect';
import CollapsibleItem from 'components/CollapsibleItem';
import Comments from 'components/List/Comments';
import css from './Issue.module.scss';
import { useUser } from '../../providers/UserProvider';
import { useFormContext } from '../../components/Form/context';
import LoadFromStandardIssuesButton from '../../components/Button/LoadFromStandardIssuesButton';
import CreateStandardIssueButton from '../../components/Button/CreateStandardIssueButton';

import Resolution from './fields/Resolution';

export const fields: FormFields = {
  severity: {
    label: 'Severity',
    component: SeveritySelect,
  },
  resolution: {
    label: 'Resolution',
    component: ResolutionSelect,
  },
  name: {
    label: 'Issue title',
  },
  description: {
    label: 'Description',
    component: TextEditor,
  },
  recommendation: {
    label: 'Recommendation',
    component: TextEditor,
  },
  comment: {
    label: 'Issue comments',
    component: Comments,
    fields: CommentFields,
  },
  standard: {
    label: 'Is standard',
  },
};

export interface IssueFormProps {
  name: string;
  value: Issue;
}

const columnsNumber: {
  [x: number]: number
} = {
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.DEFISEC)]: 4,
};

const getColummsNumber = (profile: Profile | null) => {
  if (!profile) {
    return 4;
  }

  return columnsNumber[profile?.reportType];
};

const IssueForm: React.FC<IssueFormProps> = ({ name, value }) => {
  const [collapsed, setCollapsed] = useState<boolean>(!value.name);
  const profile = useUser();
  const {
    setValue, getValues, setTextEditorValue, collapseAllCb
  } = useFormContext();

  const onSelect = (data: any) => {
    setValue(`${name}.name`, data.name, { shouldDirty: true });
    setValue(`${name}.severity`, data.severity, { shouldDirty: true });
    setValue(`${name}.resolution`, data.resolution, { shouldDirty: true });
    setTextEditorValue(`${name}.description`, data.description, {
      shouldDirty: true,
    });
    setValue(`${name}.standard`, true, { shouldDirty: true });
  };

  const getDefault = () => ({
    name: getValues(`${name}.name`),
    severity: getValues(`${name}.severity`),
    description: getValues(`${name}.description`),
  });

  const severity = useMemo(() => {
    if (value.severity === null) {
      return null;
    }

    return value?.severity?.name;
  }, [value.severity]);

  useEffect(() => {
    collapseAllCb(() => {
      setCollapsed(false);
    });
  }, [collapseAllCb]);

  const ResolutionField = useMemo(() => {
    if (!profile) {
      return null;
    }

    return Resolution[profile?.reportType];
  }, [profile]);

  return (
    <CollapsibleItem
      title={
        `${value.name}${value.standard ? ' (standard)' : ''}`
        || 'Enter issue title'
      }
      in={collapsed}
      icon={<LoadFromStandardIssuesButton onSelect={onSelect} />}
      icon2={<CreateStandardIssueButton getDefault={getDefault} />}
      badge={<Badge bg="none" className={css.badge} style={{ background: value?.severity?.color }}>{severity}</Badge>}
      className={css.cardBody}
    >
      <>
        <Row>
          <Col xs={12} md={getColummsNumber(profile)}>
            <FormFieldControl name={`${name}.name`} defaultValue={value.name} />
          </Col>
          <Col xs={12} md={getColummsNumber(profile)}>
            <FormFieldControl name={`${name}.severity`} defaultValue={value?.severity} compareFieldName="id" />
          </Col>
          {ResolutionField && <ResolutionField name={name} value={value} />}
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <FormFieldControl
              name={`${name}.description`}
              defaultValue={value.description}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormFieldControl
              name={`${name}.comment`}
              defaultValue={value.comment}
            />
          </Col>
        </Row>
      </>
    </CollapsibleItem>
  );
};

export default IssueForm;
