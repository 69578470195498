import React, { useState } from 'react';
import {
  Row, Col, Collapse, Card,
} from 'react-bootstrap';

import { Chapter } from 'interfaces';

import { FormFields } from 'components/Form';
import FormFieldControl from 'components/FormFieldControl';
import TextEditor from 'components/TextEditor';

export const fields: FormFields = {
  title: {
    label: 'Comment title',
  },
  body: {
    label: 'Comment content',
    component: TextEditor,
  },
};

interface CommentFormProps {
  name: string;
  value: Chapter;
}

const CommentForm: React.FC<CommentFormProps> = ({ name, value }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const toggleCollapse = () => setCollapsed(!collapsed);
  const issueCollapsed = value.title && collapsed;
  return (
    <Card>
      <Card.Header onClick={toggleCollapse}>
        {value.title || 'Enter comment title'}
      </Card.Header>
      <Collapse in={!issueCollapsed}>
        <Card.Body>
          <Row>
            <Col xs={12} md={12}>
              <FormFieldControl
                name={`${name}.title`}
                defaultValue={value.title}
              />
            </Col>
            <Col xs={12} md={12}>
              <FormFieldControl
                name={`${name}.body`}
                defaultValue={value.body}
              />
            </Col>
          </Row>
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default CommentForm;
