import ProtectedService from './ProtectedService';

import { fileService } from './FileService';

class AuditService extends ProtectedService {
  baseUrl = '/audits';

  userAudits = async (
    page: number,
    limit: number,
    sortBy?: string,
    sortDirection?: string,
    filter?: any,
  ) => this.get(
    null,
    `by-user?page=${page}&limit=${limit}${
      sortBy ? `&sort=${sortBy},${sortDirection}` : ''
    }${filter ? `&filter=${JSON.stringify(filter)}` : ''}`,
  );

  assign = async (id: number) => this.get(null, `${id}/assign`);

  unassign = async (id: number) => this.get(null, `${id}/unassign`);

  downloadText = async (id: number, filename: string) => fileService.downloadFile(`${this.baseUrl}/${id}/text`, filename);

  downloadPdf = async (id: number, filename: string) => fileService.downloadFile(`${this.baseUrl}/${id}/document`, filename);

  openPdf = async (id: number) => fileService.openFile(`${this.baseUrl}/${id}/document`);

  previewDocument = async (id: number, data: any) => fileService.previewDocument(
    `${this.baseUrl}/${id}/view-document`,
    data,
  );

  downloadPreview = async (id: number) => fileService.downloadBlob(`${this.baseUrl}/${id}/document`);

  saveAuditors = async (id: number, userIds: Array<number>) => this.post(
    {
      userIds,
    },
    `${id}/assign-auditors`,
  );

  deleteAudit = async (id: number) => this.delete(null, `${id}`);

  publishAudit = async (id: number, path: string, name: string) => this.post({
    path,
    name
  }, `${id}/publish`);

  duplicateAudit = async (id: number, name: string) => this.post(
    {
      name,
    },
    `${id}/duplicate`,
  );
}

export const auditService = new AuditService();

export default AuditService;
