import React, { useState } from 'react';
import { Row, Col, CloseButton } from 'react-bootstrap';
import { FaRegUser } from 'react-icons/fa';
import Button from 'components/Button/index';
import { toast } from 'react-toastify';
import { Audit, User } from '../../../interfaces';
import { auditService } from '../../../services/AuditService';
import { useAuditors } from '../../../providers/AuditorsProvider';
import { useUser } from '../../../providers/UserProvider';
import { getErrorMessage } from '../../../helpers';
import Popup from '../../Popup/Popup';
import './index.css';

const AssignAuditorButton: React.FC<{
  audit: Audit;
  short?: boolean;
  fetchAudits?: () => void;
}> = ({ audit, short = false, fetchAudits = () => {} }) => {
  const user = useUser();
  const { auditors } = useAuditors();

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [lastSavedAuditors, setLastSavedAuditors] = useState<number[]>(
    audit.editors?.map((item) => (item.editor as User).id) || [],
  );
  const [selectedAuditors, setSelectedAuditors] = useState<number[]>([]);

  const openPopup = () => {
    setSelectedAuditors([...lastSavedAuditors]);
    setShowPopup(true);
  };

  const closePopup = () => {
    if (isSaving) return;
    setShowPopup(false);
    setSelectedAuditors([]);
  };

  const save = async () => {
    if (isSaving) return;
    setIsSaving(true);
    try {
      await auditService.saveAuditors(audit.id as number, selectedAuditors);
      setShowPopup(false);
      setLastSavedAuditors([...selectedAuditors]);
      toast.success('Success');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsSaving(false);
    }
    await fetchAudits();
  };

  const toggleAuditor = (auditor: User) => {
    const newSelectedAuditors = selectedAuditors.includes(auditor.id)
      ? selectedAuditors.filter((item) => item !== auditor.id)
      : [...selectedAuditors, auditor.id];
    setSelectedAuditors(newSelectedAuditors);
  };

  return (
    <>
      <Button variant="outline-danger" onClick={openPopup}>
        <FaRegUser />
        {' '}
        {!short ? 'Add auditors' : null}
      </Button>
      <Popup showPopup={showPopup}>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={closePopup} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="mb-1">
            <p className="h5">Select auditors you want to add</p>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <div className="list">
              {auditors.map((auditor) => {
                const checked = selectedAuditors.some(
                  (item) => item === auditor.id,
                );
                const disabled = auditor.id === audit.currentUser?.id
                  || (auditor.id === user?.id && !user.isAdmin);
                return (
                  <div className="list-item" key={auditor.id}>
                    <input
                      type="checkbox"
                      id={`auditor_${auditor.id}`}
                      disabled={disabled}
                      checked={checked}
                      onChange={() => toggleAuditor(auditor)}
                    />
                    <label htmlFor={`auditor_${auditor.id}`}>
                      {auditor.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button className="btn-success m-0" onClick={save}>
              Save
            </Button>
          </Col>
        </Row>
      </Popup>
    </>
  );
};

export default AssignAuditorButton;
