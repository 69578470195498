import React, { useState, useEffect } from 'react';
import {
  severityService
} from 'services/SeverityService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'helpers';
import Loader from 'components/Loader';
import {
  CloseButton,
  Col,
  Form,
  ListGroup,
  Row
} from 'react-bootstrap';
import {
  FaArrowDown,
  FaArrowUp,
  FaEdit,
  FaRegPlusSquare,
  FaTimes,
  FaUndo
} from 'react-icons/fa';
import Popup from 'components/Popup/Popup';
import Button from 'components/Button';
import Input from 'components/Input';
import { Severity } from 'interfaces';

const SeverityPanel = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [severityList, setSeverityList] = useState<Severity[]>([]);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showDisable, setShowDisable] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const [newItem, setNewItem] = useState<string>('');
  const [newItemColor, setNewItemColor] = useState<string>('#000000');
  const [newItemDescription, setNewItemDescription] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [order, setOrder] = useState<number[]>();

  const fetchSeverityList = async () => {
    setLoading(true);
    try {
      const severity = await severityService.getSeverityList();
      setSeverityList(severity);
      setOrder(severity.map((s: Severity) => s.id));
    } catch (e) {
      toast.error(`Fetch severity error. ${getErrorMessage(e)}`);
    } finally {
      setLoading(false);
    }
  };

  const onCreateClose = () => {
    setShowCreate(false);
    setNewItem('');
    setNewItemColor('#000000');
    setNewItemDescription('');
  };

  const createItem = async () => {
    if (!newItem) return;
    try {
      await severityService.createSeverity(newItem, newItemColor, newItemDescription);
      onCreateClose();
      toast.success('Item saved');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      fetchSeverityList();
    }
  };

  const onDisableClose = () => {
    setShowDisable(false);
    setSelectedItem(null);
  };

  const disableItem = async () => {
    try {
      if (selectedItem.isDeleted) {
        await severityService.activateSeverity(selectedItem.id);
      } else {
        await severityService.deleteSeverity(selectedItem.id);
      }
      onDisableClose();
      toast.success(
        `Item ${selectedItem.isDeleted ? 'enabled' : 'disabled'}`,
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      fetchSeverityList();
    }
  };

  const onDisableConfirm = (severity: Severity) => {
    setShowDisable(true);
    setSelectedItem(severity);
  };

  const onEditClose = () => {
    setShowEdit(false);
    setNewItem('');
    setNewItemColor('#000000');
    setNewItemDescription('');
    setSelectedItem(null);
  };

  const editItem = async () => {
    if (!newItem) return;
    try {
      await severityService.editSeverity(selectedItem.id, newItem, newItemColor, newItemDescription);
      onEditClose();
      toast.success('Item updated');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      fetchSeverityList();
    }
  };

  const onEditConfirm = (severity: Severity) => {
    setShowEdit(true);
    setNewItem(severity.name);
    setSelectedItem(severity);
    setNewItemColor(severity.color);
    setNewItemDescription(severity.description);
  };

  const onNewItemTyped = (event: any) => {
    setNewItem(event.target.value);
  };

  const onNewItemColorChange = (event: any) => {
    setNewItemColor(event.target.value);
  };

  const onNewItemDescriptionTyped = (event: any) => {
    setNewItemDescription(event.target.value);
  };

  const onOrderChange = async (newOrder: number[]) => {
    if (!newOrder) return;
    try {
      await severityService.changeOrder(newOrder);

      toast.success('Item updated');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      fetchSeverityList();
    }
  };

  const move = (oldIndex: number, newIndex: number) => {
    if (order) {
      const newOrder = order;
      [newOrder[oldIndex], newOrder[newIndex]] = [newOrder[newIndex], newOrder[oldIndex]];
      onOrderChange(newOrder);
    }
  };

  const moveItem = (index: number, up = false) => {
    move(index, index + (up ? -1 : 1));
  };

  useEffect(() => {
    fetchSeverityList();
  }, []);

  return (
    <div className="form-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
          <ListGroup>
            {
              severityList.map((severity: Severity, index: number, array: Severity[]) => (
                <div className="field-with-buttons" key={severity.id}>
                  <ListGroup.Item className="list-group-item__enum">
                    <div className="name-color">
                      <span className="severity-color-badge" style={{ backgroundColor: severity.color }} />
                      {severity.name}
                    </div>
                    <Button
                      className="item-btn item-btn_disable"
                      title={severity.isDeleted ? 'Enable' : 'Disable'}
                      size="sm"
                      variant={severity.isDeleted ? 'primary' : 'danger'}
                      onClick={() => onDisableConfirm(severity)}
                    >
                      {severity.isDeleted ? <FaUndo /> : <FaTimes />}
                    </Button>
                    <Button
                      className="item-btn item-btn_edit"
                      title="Edit"
                      size="sm"
                      variant="secondary"
                      onClick={() => onEditConfirm(severity)}
                    >
                      <FaEdit />
                    </Button>
                  </ListGroup.Item>
                  <Button
                    title="Move down"
                    size="sm"
                    variant="primary"
                    onClick={() => moveItem(index)}
                    disabled={index === array.length - 1}
                  >
                    <FaArrowDown />
                  </Button>
                  <Button
                    title="Move up"
                    size="sm"
                    variant="primary"
                    onClick={() => moveItem(index, true)}
                    disabled={index === 0}
                  >
                    <FaArrowUp />
                  </Button>
                </div>
              ))
            }
          </ListGroup>
          <Button
            className="d-flex align-items-center add-new-btn mr-2"
            variant="primary"
            onClick={() => setShowCreate(true)}
          >
            <FaRegPlusSquare className="mx-2" />
            {' '}
            Add new
          </Button>
          <Popup showPopup={showCreate}>
            <Row className="mb-4">
              <Col className="d-flex justify-content-end">
                <CloseButton onClick={() => onCreateClose()} />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <div className="create-text-wrapper">
                  <p className="mb-2">Create new item</p>
                </div>
                <div className="text-color-input">
                  <Input value={newItem} onChange={onNewItemTyped} />
                  <Input
                    value={newItemColor}
                    onChange={onNewItemColorChange}
                    type="color"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Control
                  as="textarea"
                  value={newItemDescription}
                  onChange={onNewItemDescriptionTyped}
                  className="description-textarea"
                  rows={10}
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  className="btn-success m-0"
                  onClick={createItem}
                  disabled={!newItem}
                >
                  Create
                </Button>
              </Col>
            </Row>
          </Popup>
          <Popup showPopup={showDisable}>
            <Row className="mb-4">
              <Col className="d-flex justify-content-end">
                <CloseButton onClick={() => onDisableClose()} />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <p className="h5 mb-2">
                  {`Do you want to ${
                    selectedItem?.isDeleted ? 'Enable' : 'Disable'
                  } this item?`}
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  className={`m-0 btn-${
                    selectedItem?.isDeleted ? 'success' : 'danger'
                  }`}
                  onClick={disableItem}
                >
                  {selectedItem?.isDeleted ? 'Enable' : 'Disable'}
                </Button>
              </Col>
            </Row>
          </Popup>
          <Popup showPopup={showEdit}>
            <Row className="mb-4">
              <Col className="d-flex justify-content-end">
                <CloseButton onClick={() => onEditClose()} />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <div className="create-text-wrapper">
                  <p className="mb-2">Edit item</p>
                </div>
                <div className="text-color-input">
                  <Input value={newItem} onChange={onNewItemTyped} />
                  <Input
                    value={newItemColor || selectedItem?.color}
                    onChange={onNewItemColorChange}
                    type="color"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Control
                  as="textarea"
                  value={newItemDescription}
                  onChange={onNewItemDescriptionTyped}
                  className="description-textarea"
                  rows={10}
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  className="btn-success m-0"
                  onClick={editItem}
                  disabled={!newItem}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          </Popup>
        </>
      )}
    </div>
  );
};

export default SeverityPanel;
