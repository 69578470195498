import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { authService } from 'services/AuthService';
import Loader from 'components/Loader';
import Button from '../Button';

interface UserProfile {
  name: string;
}

const useProfile = () => {
  const [profile, setProfile] = useState<UserProfile>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const profile = await authService.profile();
        setProfile(profile);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  return { profile, loading };
};

const ProfileInfo = () => {
  const { profile, loading } = useProfile();
  const history = useHistory();

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Card>
          <Card.Body>
            <div>
              Profile:
              {profile?.name}
            </div>
            <div>
              <Button onClick={() => history.push('/user/logout')}>Logout</Button>
              <a href="/user/change-pwd">
                <Button>Change password</Button>
              </a>
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default ProfileInfo;
