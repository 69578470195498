import React, { useEffect, useState } from 'react';

import { severityService } from 'services/SeverityService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'helpers';
import { Severity } from 'interfaces';
import Select, { SelectProps } from './Select';

// eslint-disable-next-line react/display-name
const SeveritySelect: React.FC<SelectProps> = React.forwardRef((props, ref) => {
  const [options, setOptions] = useState([]);

  const fetchSeverityList = async () => {
    try {
      const severity = await severityService.getSeverityList();

      setOptions(severity.map((item: Severity) => ({
        value: item,
        label: item.name,
        isDisabled: item.isDeleted,
      })));
    } catch (e) {
      toast.error(`Fetch severity error. ${getErrorMessage(e)}`);
    }
  };

  useEffect(() => {
    fetchSeverityList();
  }, []);

  return <Select {...props} options={options} ref={ref} />;
});

export default SeveritySelect;
