import ProtectedService from './ProtectedService';

class ForkCheckerService extends ProtectedService {
  baseUrl = '/contracts-verifier';

  checkByAddress = async (
    address: string,
    blockchain: string,
    net?: string,
  ) => this.get(
    null,
    `extract-entities?address=${address}&blockchain=${blockchain}${net ? `&net=${net}` : ''}`,
  );

  checkByText = async (text: string) => this.post(
    {
      contract: text
    },
    'extract-entities',
  );
}

export const forkCheckerService = new ForkCheckerService();

export default ForkCheckerService;
