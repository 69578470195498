import BaseService from './BaseService';

const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';

class ProtectedService extends BaseService {
  accessToken: string = '';

  constructor() {
    super();
    this.getAccessToken();
  }

  makeProtectedRequest = async (fn: any) => {
    try {
      const Authorization = this.getAuthHeader();
      if (!Authorization) throw new Error('No Authorization');
      const authHeaders = { Authorization };
      return await fn(authHeaders);
    } catch (e) {
      if (e.response?.status === 401) {
        this.clearAccessToken();
      }
      throw e;
    }
  };

  get = async (params: any = {}, subUrl?: string, headers?: any) => this.makeProtectedRequest(
    async (authHeaders: any) => super.get(params, subUrl, {
      ...authHeaders,
      ...headers,
    }),
  );

  post = async (params: any = {}, subUrl?: string, headers?: any) => this.makeProtectedRequest(
    async (authHeaders: any) => super.post(params, subUrl, {
      ...authHeaders,
      ...headers,
    }),
  );

  patch = async (params: any = {}, subUrl?: string, headers?: any) => this.makeProtectedRequest(
    async (authHeaders: any) => super.patch(params, subUrl, {
      ...authHeaders,
      ...headers,
    }),
  );

  delete = async (params: any = {}, subUrl: string, headers?: any) => this.makeProtectedRequest(
    async (authHeaders: any) => super.delete(params, subUrl, {
      ...authHeaders,
      ...headers,
    }),
  );

  getAuthHeader = () => {
    const accessToken = this.getAccessToken();
    if (accessToken) return `Bearer ${accessToken}`;
    return null;
  };

  setAccessToken = (token: string) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  };

  getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY) || '';

  clearAccessToken = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  };
}

export default ProtectedService;
