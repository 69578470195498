import React, { useEffect, useRef } from 'react';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';

import { useFormContext } from 'components/Form/context';

import { SelectOption } from 'interfaces';
import { getFormFieldError } from 'components/Form';
import { isEmpty } from 'helpers';

const BLUE = '#2684FF';
const RED = '#dc3545';

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 1000
  })
};

export interface SelectProps {
  onChange: (e: any) => void;
  name: string;
  value: any;
  defaultValue?: any;
  comparefieldname?: string;
  control?: any;
  ref: any;
  options: SelectOption[];
  disabled?: boolean;
  multiple?: boolean;
  multipleProp?: string;
  components?: any;
  addValueFn?: (() => { key: number }) | null;
}

// eslint-disable-next-line react/display-name
const Select: React.FC<SelectProps> = React.forwardRef((selectProps, ref) => {
  const {
    options,
    defaultValue,
    name,
    control,
    disabled,
    multiple = false,
    multipleProp,
    components,
    addValueFn,
    comparefieldname,
  } = selectProps;

  const {
    formState: { errors }
  } = useFormContext();
  const selectInputRef = useRef();
  const firstError = React.useMemo(() => (Object.keys(errors)[0]), [errors]);
  const fieldError = React.useMemo(() => getFormFieldError(name, errors), [errors]);

  const setCutomStyles = React.useMemo(() => {
    if (name === firstError || !isEmpty(fieldError)) {
      return {
        ...customStyles,
        control: (provided: any, state: any) => ({
          ...provided,
          borderColor: state.hasValue ? BLUE : RED,
          boxShadow: state.hasValue ? `0 0 0 1px ${BLUE}` : '0 0 0 0.25rem rgb(220 53 69 / 25%)',
          '&:hover': {
            borderColor: state.hasValue ? BLUE : RED
          }
        })
      };
    }

    return customStyles;
  }, [errors]);

  useEffect(() => {
    if (addValueFn) {
      // @ts-ignore
      selectInputRef.current.select.selectOption({
        value: addValueFn().key
      });
    }
  }, [addValueFn]);

  useEffect(() => {
    if (name === firstError) {
      // @ts-ignore
      selectInputRef.current.focus();
    }
  }, [errors]);

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name as `${string}`}
      render={(props: any) => {
        const { value, onChange } = props.field;
        let _value;
        if (multiple) {
          const values = value.map((item: any) => item[multipleProp!]);
          _value = options.filter((c) => values.includes(c.value));
        } else {
          if (comparefieldname) {
            _value = options.find((c) => c.value?.[comparefieldname] === value?.[comparefieldname]);
          } else {
            _value = options.find((c) => c.value === value);
          }

          if (!_value) {
            _value = null;
          }
        }
        const _onChange = (val: any) => {
          if (multiple) {
            onChange(
              val.map((item: any) => {
                const existValue = defaultValue.find((dItem: any) => dItem[multipleProp!] === item.value);
                return existValue
                  ? {
                    ...existValue,
                  }
                  : {
                    [multipleProp!]: item.value,
                  };
              })
            );
          } else {
            onChange(val.value);
          }
        };
        return (
          <ReactSelect
            // @ts-ignore
            ref={selectInputRef}
            inputRef={ref}
            components={components}
            options={options}
            value={_value}
            onChange={_onChange}
            styles={setCutomStyles}
            isDisabled={disabled}
            isMulti={multiple}
          />
        );
      }}
    />
  );
});

export default Select;
