import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge, Row, Col, Collapse, Card, Stack
} from 'react-bootstrap';

import {
  Contract,
} from 'interfaces';

import { FormFields } from 'components/Form';
import FormFieldControl from 'components/FormFieldControl';
import Issues from 'components/List/Issues';
import Chapters from 'components/List/Chapters';
import { EnumTypes } from 'services/EnumsService';
import { useEnums } from 'providers/EnumsProvider';
import { useFormContext } from 'components/Form/context';

import { fields as IssueFields } from 'forms/Issue';
import { fields as ChapterFields } from 'forms/Chapter';
import css from './Contract.module.scss';
import TextEditor from '../../components/TextEditor';
import ContractTypeSelect from '../../components/Select/ContractTypeSelect';

export const fields: FormFields = {
  name: {
    label: 'Contract title',
  },
  description: {
    label: 'Overview',
    component: TextEditor,
  },
  address: {
    label: 'Contract address',
  },
  type: {
    label: 'Type',
    component: ContractTypeSelect,
  },
  issues: {
    label: 'Contract issues',
    component: Issues,
    fields: IssueFields,
  },
  chapters: {
    label: 'Contract chapters',
    component: Chapters,
    fields: ChapterFields,
  },
};

interface ContractFormProps {
  name: string;
  value: Contract;
  index: number;
}

const ContractForm: React.FC<ContractFormProps> = ({ name, value, index }) => {
  const [collapsed, setCollapsed] = useState<boolean>(!value.name);
  const { filterEnums } = useEnums();
  const { collapseAllCb } = useFormContext();
  const toggleCollapse = () => setCollapsed(!collapsed);
  const contractType = useMemo(() => {
    if (value.type === null) {
      return null;
    }

    return filterEnums(EnumTypes.CONTRACT_TYPE).find((type) => type.key === value.type)?.value;
  }, [value.type]);

  useEffect(() => {
    collapseAllCb(() => {
      setCollapsed(false);
    });
  }, [collapseAllCb]);

  return (
    <Card>
      <Card.Header onClick={toggleCollapse}>
        <Stack gap={3} direction="horizontal" className="d-flex align-items-start justify-content-between">
          <div>
            <span className={css.index}>{`C${index + 1}.`}</span>

            {value.name || 'Enter contract title'}
          </div>

          {contractType as boolean && <Badge bg="dark">{contractType}</Badge>}
        </Stack>
      </Card.Header>
      <Collapse in={collapsed}>
        <Card.Body className={css.cardBody}>
          <Row>
            <Col>
              <FormFieldControl
                name={`${name}.name`}
                defaultValue={value.name}
              />
            </Col>
            <Col>
              <FormFieldControl
                name={`${name}.address`}
                defaultValue={value.address}
              />
            </Col>
            <Col>
              <FormFieldControl
                name={`${name}.type`}
                defaultValue={value.type}
              />
            </Col>
          </Row>
          <>
            <Row>
              <Col>
                <FormFieldControl
                  name={`${name}.description`}
                  defaultValue={value.description}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormFieldControl
                  name={`${name}.chapters`}
                  defaultValue={value.chapters}
                />
              </Col>
            </Row>
          </>
          <Row className="mt-4">
            <Col>
              <FormFieldControl
                name={`${name}.issues`}
                defaultValue={value.issues}
              />
            </Col>
          </Row>
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default ContractForm;
