import React, { useCallback, useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { auditService } from 'services/AuditService';

import Button from 'components/Button/index';
import { getErrorMessage } from 'helpers';
import { AuditStatus } from 'interfaces';

interface DownloadPDFReportButtonProps {
  id: number;
  short?: boolean;
  name: string;
  status?: AuditStatus;
}

const prepareName = (name: string) => name.replaceAll(/\s/g, '-');

const getFilename = (
  name: string,
) => {
  const preparedName = prepareName(name);
  return `${preparedName}_final-audit-report_${Date.now()}.txt`;
};

const DownloadPDFReportButton: React.FC<DownloadPDFReportButtonProps> = ({
  id,
  short,
  name,
  status,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const downloadPDF = useCallback(async () => {
    setLoading(true);
    try {
      await auditService.downloadText(
        id,
        getFilename(name),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [id, name, status]);
  return (
    <Button variant="success" onClick={downloadPDF} loading={loading}>
      <FaDownload />
      {' '}
      {short ? null : 'Download Text'}
    </Button>
  );
};

export default DownloadPDFReportButton;
