import ProtectedService from './ProtectedService';

class StandardIssuesService extends ProtectedService {
  baseUrl = '/standard-issues';

  getList = async (
    page: number,
    limit: number,
    sortBy?: string,
    sortDirection?: string,
    filter?: any,
  ) => this.get(
    null,
    `?page=${page}&limit=${limit}${
      sortBy ? `&sort=${sortBy},${sortDirection}` : ''
    }${filter ? `&filter=${JSON.stringify(filter)}` : ''}`,
  );
}

export const standardIssuesService = new StandardIssuesService();

export default StandardIssuesService;
