import ProtectedService from './ProtectedService';

const downloadFile = (
  data: string | ArrayBuffer | ArrayBufferView | Blob,
  filename: string,
  mime?: string,
  bom?: string | Uint8Array,
): void => {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(blob)
      : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(() => {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};

class FileService extends ProtectedService {
  downloadBlob = async (url: string) => {
    const response = await this.api.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: this.getAuthHeader(),
      },
    });
    return response.data as Blob;
  };

  previewDocument = async (url: string, data: any) => {
    const response = await this.api.post(url, data, {
      responseType: 'blob',
      headers: {
        Authorization: this.getAuthHeader(),
      },
    });
    const fileURL = window.URL.createObjectURL(response.data);
    window.open(fileURL, '_blank');
  };

  downloadFile = async (url: string, filename: string) => {
    const data = await this.downloadBlob(url);
    downloadFile(data, filename, 'application/pdf');
  };

  openFile = async (url: string) => {
    const data = await this.downloadBlob(url);
    const fileURL = window.URL.createObjectURL(data);
    window.open(fileURL, '_blank');
  };
}

export const fileService = new FileService();

export default FileService;
