import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { getClassificatorValue, ReportTemplate, ReportTemplatePrefix } from 'interfaces';
import FormFieldControl from 'components/FormFieldControl';
import { CompanyMap, FieldProps } from '../interfaces';

const ChaptersField: React.FC<FieldProps> = () => (
  <Row>
    <Col>
      <FormFieldControl name="chapters" />
    </Col>
  </Row>
);

const chapters: CompanyMap = {
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.DEFISEC)]: ChaptersField,
};

export default chapters;
